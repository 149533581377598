import React, { useEffect, useState } from 'react';
import Apis from '../../../api/api';
import { useSelector } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import ModelCheck from '../../../model/ModelCheck';
import LoadingView from '../../../components/Loading';

const HomeAddNote = () => {
    const [loading, setloading] = useState(false)
    const [note, setnote] = useState("")

    /** @type {ModelCheck} */
    const check = useSelector(state => state.checkProduct)

    useEffect(() => {
        if (check) {

            getNotes()
        }
    }, [])

    async function getNotes() {
        let notesGotten = await Apis.getNotes(check.id)
        setnote(notesGotten.notes)
    }

    function onChange(event) {
        setnote(event.target.value)
    }

    async function onClickSave() {
        setloading(true)
        await Apis.sendNotes(check.id, note)

        await getNotes()
        setloading(false)
    }

    return (
        <div>
            <h5>Add a note</h5>
                {
                    loading ? <LoadingView/> :
                    <div className="">
                        <textarea onChange={onChange} style={{height: "450px"}} value={note}></textarea>
                        <button className='btn btn-primary' onClick={onClickSave}>Save note</button>
                    </div>
                }
        </div>
    )
}

export default HomeAddNote