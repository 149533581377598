import React, { useEffect, useState } from 'react';
import Apis from '../../../api/api'; 
import { IconDelete } from '../../../components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TagColors from '../../../components/TagColors';
import {  DateFormatterOnlyDate, DateFormatterOnlyTime } from '../../../components/DateFormat';
import LoadingView from '../../../components/Loading';
import SelectStatus from '../../../components/selectors/SelectStatus';

const TableTable = () => { 
    const [loading, setloading] = useState(false)
    const [tableData, settableData] = useState(null)
    const [filteredTableData, setfilteredTableData] = useState([])
    const [page, setpage] = useState(1)
    const [lastPage, setlastPage] = useState(1)
    const [pageButtons, setpageButtons] = useState(null)

    const params = useSelector((state) => state.checkParams)
    const searchBar = useSelector((state) => state.checkSearchBar)
    const searchBarCheckId = useSelector((state) => state.checkSearchBarCheckId)
    const enabledDelete = useSelector((state) => state.enableDeleteItemFromTable)

    const dispatch = useDispatch()

    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
    }, [params, page])


    useEffect(() => {     
        fetchFilteredDataBySearchBar()
    }, [searchBar])


    useEffect(() => {     
        fetchFilteredDataByCheckId()
    }, [searchBarCheckId])

    
    async function fetchFilteredDataBySearchBar() {
        setloading(true)
        let data = await Apis.getAllChecks(page, params.team_id, params.country_id, params.year, params.brand_id, params.sport, params.checkType, params.checkStatus, params.userReport, params.userId, params.bug_status, searchBar, null, null)  
        setfilteredTableData(data.checks)
        setloading(false)
    }
    
    async function fetchFilteredDataByCheckId() {
        setloading(true)
        let data = await Apis.getAllChecks(page, params.team_id, params.country_id, params.year, params.brand_id, params.sport, params.checkType, params.checkStatus, params.userReport, params.userId, params.bug_status, null, searchBarCheckId, params.season)  
        setfilteredTableData(data.checks)
        setloading(false)
    }
    
    async function fetchFilteredData() {
        setloading(true)
        console.log("fetching")
        console.log(params)
        let data = await Apis.getAllChecks(page, params.team_id, params.country_id, params.year, params.brand_id, params.sport, params.checkType, params.checkStatus, params.userReport, params.userId, params.bug_status, params.text, params.check_id, params.season,)  
        setfilteredTableData(data.checks)
        setloading(false)
    }
    
    async function fetchData() { 
        setloading(true)
        let data = await Apis.getAllChecks(page, params.team_id, params.country_id, params.year, params.brand_id, params.sport, params.checkType, params.checkStatus, params.userReport, params.userId, params.bug_status, null, null,params.season)  
        settableData(data.checks)
        setfilteredTableData(data.checks)
        setlastPage(data.lastPage)

        let pageButtonsList = []
        for (var i = 0; i < data.lastPage; i++) {
            pageButtonsList.push({
                index: i + 1 
            })
        }

        setpageButtons(pageButtonsList)
        setloading(false)
    } 


    function changePage(index) {
        if (index === -1) {
            if (page > 1 ) {
                setpage((prev) => (prev + index))
            }
        } else {
            if (page < lastPage) { 
                setpage((prev) => (prev + index))
            }
        }

    }

    function showLastPage() {
        setpage(lastPage)
    }

    function onClickCheck(checkId) {
        // navigate({pathname: "/analysis", search: `id=${checkId}`})

        const currentUrl = window.location.href.split("/checks")[0]
        // Specify the URL you want to open in the new tab
        const url = `${currentUrl}/analysis?id=${checkId}`;
        // Open a new tab
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    async function onClickDelete(checkId) {
        if (enabledDelete) {
            setloading(true)
            await Apis.deleteCheck(checkId)
            await fetchData()
            setloading(false)
        }
    }

    async function onSelectAdminResult(checkId, resultId) {
        setloading(true)
        await Apis.sendAdminStatus(checkId, resultId)
        await fetchData()
        setloading(false)
    }

    const urlPhoto = "https://admin.kitlegit.com/storage?"

    const generatePageNumbers = () => {
        const lista = []
        for (var i = 1; i <= lastPage; i++) {
            lista.push(i)
        }
        return lista
    }

    return (
        <div> 
            {
                loading ? <div className='loading-table'>
                    <LoadingView/>
                </div> : 

                <div>
                    <table className='table-big'>
                        
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Team</th>
                                <th>Brand</th>
                                <th>Season</th>
                                <th>User</th>  
                                <th>Date</th>  
                                <th>Score</th>
                                <th>System Result</th>
                                <th>Admin Result</th>
                                <th>Bug Status</th>
                                <th>Del.</th>
                            </tr>
                        </thead>

                        <tbody> 
                            {
                                filteredTableData && 
                                filteredTableData.map((e) => { 
                                    return (
                                        <tr key={e.id} >
                                            <td  className='clicable' onClick={() =>{onClickCheck(e.id)}}>{e.id}</td>
                                            <td  className='clicable' onClick={() =>{onClickCheck(e.id)}}>
                                                <div>
                                                    <img className='table-thumb' src={`${urlPhoto}product_id=${e.id}&file=${e.photo}` } alt="" />
                                                    <span>{e.team}</span>
                                                </div>
                                            </td>
                                            <td  className='clicable' onClick={() =>{onClickCheck(e.id)}}>{e.brand}</td>
                                            <td>{e.year}</td>
                              {/** user */} <td>{e.email}</td>
                              {/** date */} <td>
                                                {
                                                    e.date == null ? "" :
                                                    <div>
                                                        <p style={{margin: 0, fontSize: 12}}>{DateFormatterOnlyDate(e.date)}</p>
                                                        <p style={{margin: 0, fontSize: 12}}>{DateFormatterOnlyTime(e.date)}</p>
                                                    </div>
                                                }
                                            </td>
                                            <td>{e.score}</td>
                       {/*system result*/}  <td><TagColors status={e.checkStatusName}/></td>
                        {/*admin result*/}  <td style={{marginInline: 10}}>
                                                <SelectStatus isAdmin={true} initialId={e.adminResultStatusId} callback={(id) => onSelectAdminResult(e.id, id)}/>
                                            </td>
                                            <td style={{paddingLeft: 10}}>{e.productReview != null ? e.productReview.status_bug : ""}</td>
                               {/*delete*/} <td><div className={enabledDelete ? 'clicable' : ""} onClick={() => {onClickDelete(e.id)}}><IconDelete color={ enabledDelete ? "red" : "grey" } /> </div></td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td></td>
                            </tr>
                        </tbody>


                    </table>

                    <div className='flex my-3' style={{width: "80%", marginInline: "auto"}}>
                        <button className='btn btn-primary mx-2' onClick={() => changePage(-1)}>Previous page</button>
                        <div style={{maxWidth: "50%"}}>
                            <span className='mx-2'>Current page: {page}</span>
                            <div style={{width: "100%", overflowX: "scroll", display: "flex"}}>
                                {
                                    generatePageNumbers().map(pageNumber => {
                                        return (
                                            <button 
                                                key={pageNumber}
                                                className={pageNumber === page ? 'btn btn-primary mx-1' : 'btn btn-secondary mx-1'} 
                                                onClick={() => { setpage(pageNumber) }}
                                            >
                                                {pageNumber}
                                            </button>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <button className='btn btn-primary mx-2' onClick={() => changePage(1)}>Next page</button> 
                        <button className='btn btn-primary mx-2' onClick={() => showLastPage()}>Last page</button>
                    </div>
                    
                    

                </div>
            }
           
        </div>
    )
}

export default TableTable