import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_OPTIONS } from '../../store/defaultOptions';

const SelectVersion = ({initialId, callback}) => {
    const configs = DEFAULT_OPTIONS.versionsDropdown
    const [selectedValue, setSelectedValue] = useState(initialId);

    function onSelect(event) {
        let value = (event.target.value)
        setSelectedValue(value)
        callback(value)
        console.log(value)
        console.log(typeof(value))
    }
    
    return (
        <div>
            <p></p>
            <select className="form-select" value={(selectedValue) ?? configs[0]} onChange={onSelect} name='team_id'>
            {
                configs.map((e) => {
                    return (
                        <option key={e.id} value={e.id}>{e.name}</option>
                    )
                })
            }
            </select>
        </div> 
    )
}

export default SelectVersion