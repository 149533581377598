import React, { useEffect, useState } from 'react';
import SelectTeam from '../../../components/selectors/SelectTeam';
import SelectSport from '../../../components/selectors/SelectSport';
import SelectSeason from '../../../components/selectors/SelectSeason';
import SelectBrand from '../../../components/selectors/SelectBrand';
import SelectType from '../../../components/selectors/SelectType';
import SelectCondition from '../../../components/selectors/SelectCondition';
import SelectCategory from '../../../components/selectors/SelectCategory';
import SelectPurchasePlace from '../../../components/selectors/SelectPurchasePlace';
// eslint-disable-next-line no-unused-vars
import ModelCheck, { ModelJersey } from '../../../model/ModelCheck';
import { SelectNeckLabel } from '../../../components/selectors/NeckLabel';
import SelectCountry from '../../../components/selectors/Countries';
import { SelectCareLabel, SelectCareLabelMarkings, SelectCareLabelQrCode } from '../../../components/selectors/CareLabel';
import { SelectStyleCodeLabel } from '../../../components/selectors/StyleCodeLabel';
import { SelectSecurityFeatures } from '../../../components/selectors/SecurityFeatures';
import { SelectSwingTag, SelectSwingTagBarcode, SelectSwingTagHologram } from '../../../components/selectors/SwingTag';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentCheckJersey, setCurrentCheckProduct } from '../../../store/actions';
import SelectSize from '../../../components/selectors/SelectSize';

const CheckJerseyData = () => {


    const dispatch = useDispatch()

    /**
     * @type {ModelCheck}
     */
    const checkJersey = useSelector(state => (state.checkJersey))


    // all jersey info here
    const [newJerseyData, setnewJerseyData] = useState({})

    useEffect(() => {
        setnewJerseyData(checkJersey)
    }, [])

    useEffect(() => {
        dispatch(setCurrentCheckJersey(newJerseyData))
    }, [newJerseyData])



    function onChange(event) {
        const { name, value } = event.target
        // setupcBarcode(value)
        setnewJerseyData(prev => {
            const newData = {...prev, [name]: value}
            return newData
        })

    }

    function onSelect(name, value) {
        setnewJerseyData(prev => {
            const newData = {...prev, [name]: value}
            return newData
        })
    }

    return (
    <div>
        
        <div>
            <h5>Neck label</h5>
            <div className="grid g4-columns">
                <div className="mb-3">
                    <label htmlFor="neck_label" className="form-label">Neck label</label>
                    <SelectNeckLabel initialId={newJerseyData.neck_label} callback={(id) => {onSelect("neck_label", id)}}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="neck_label_country_id" className="form-label"> Country of manuf. </label>
                    <SelectCountry initialId={newJerseyData.neck_label_country_id} callback={(id) => {onSelect("neck_label_country_id", id)}}  />
                </div>
            </div>
        </div>	

        <hr/>
        
        <div>
            <h5>Care label</h5>
            <div className="grid g4-columns">
                <div className="mb-3">
                    <label htmlFor="care_label" className="form-label"> Care label </label>
                    <SelectCareLabel initialId={newJerseyData.care_label} callback={(id) => {onSelect("care_label", id)}}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="care_label_country_id" className="form-label"> Country of manuf. </label>
                    <SelectCountry initialId={newJerseyData.care_label_country_id} callback={(id) => {onSelect("care_label_country_id", id)}}  />
                </div>
                <div className="mb-3">
                    <label htmlFor="care_label_markings" className="form-label"> Markings </label>
                    <SelectCareLabelMarkings initialId={newJerseyData.care_label_markings} callback={(id) => {onSelect("care_label_markings", id)}} />
                </div>
                <div className="mb-3">
                    <label htmlFor="style_code" className="form-label"> Style code </label>
                    <input className="form-control" name="care_label_code"   value={newJerseyData.care_label_code } onChange={onChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="qr_code" className="form-label"> QR Code </label>
                    <SelectCareLabelQrCode initialId={newJerseyData.qr_code} callback={(id) => {onSelect("qr_code", id)}} />
                </div>
                <div className="mb-3">
                    <label htmlFor="qr_code_number" className="form-label"> QR Code Value </label>
                    <input className="form-control" name="qr_code_number"   value={newJerseyData.qr_code_number  } onChange={onChange} />
                </div>
            </div>
        </div>	

        <hr/>
        
        <div>
            <h5>Style code label</h5>
            <div className="grid g4-columns">
                <div className="mb-3">
                    <label htmlFor="style_code_label" className="form-label">Style code label</label>
                    <SelectStyleCodeLabel initialId={newJerseyData.style_code_label} callback={(id) => {onSelect("style_code_label", id)}} />

                </div>
                <div className="mb-3">
                    <label htmlFor="item_code" className="form-label">Item code</label>
                    <input className="form-control" name="item_code" value={newJerseyData.item_code } onChange={onChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="style_code" className="form-label">Style code</label>
                    <input className="form-control" name="style_code"       value={newJerseyData.style_code } onChange={onChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="upc_style_code_label" className="form-label">UPC Barcode</label>
                    <input className="form-control" name="upc_style_code_label"      value={newJerseyData.upc_style_code_label } onChange={onChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="size" className="form-label">Size</label>
                    <SelectSize initialId={newJerseyData.size} callback={(id) => {onSelect("size", id)}}/>
                </div>
            </div>
        </div>	

        <hr/>
        
        <div>
            <h5>Security</h5>
            <div className="grid g4-columns">
                <div className="mb-3">
                    <label htmlFor="authenticity_marking" className="form-label">Security features</label>
                    <SelectSecurityFeatures initialId={newJerseyData.authenticity_marking} callback={(id) => {onSelect("authenticity_marking", id)}} />
                </div>
                <div className="mb-3">
                    <label htmlFor="authenticity_number" className="form-label">Security code</label>
                    <input className="form-control" name="authenticity_number" value={newJerseyData.authenticity_number} onChange={onChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="authenticity_number2" className="form-label">Security code 2</label>
                    <input className="form-control" name="authenticity_number2" value={newJerseyData.authenticity_number2} onChange={onChange} />
                </div>
            </div>
        </div>	

        <hr/>
        
        <div>
            <h5>Swing tag</h5>
            <div className="grid g4-columns">
                <div className="mb-3">
                    <label htmlFor="swing_tag" className="form-label">Swing tag</label>
                    <SelectSwingTag initialId={newJerseyData.swing_tag} callback={(id) => {onSelect("swing_tag", id)}}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="barcode_type" className="form-label">Barcode</label>
                    <SelectSwingTagBarcode initialId={newJerseyData.barcode_type} callback={(id) => {onSelect("barcode_type", id)}}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="swing_tag_style_code" className="form-label">Style code</label>
                    <input className="form-control" name="swing_tag_style_code"  value={newJerseyData.swing_tag_style_code } onChange={onChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="upc_barcode" className="form-label">UPC Barcode 1</label>
                    <input className="form-control" name="upc_barcode"  value={newJerseyData.upc_barcode } onChange={onChange} />
                </div>
            </div>
            <div className="grid g4-columns">
                <div className="mb-3">
                    <label htmlFor="upc_barcode" className="form-label">UPC Barcode 2</label>
                    <input className="form-control" name="upc_barcode2"  value={newJerseyData.upc_barcode2 } onChange={onChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="authenticity_hologram_text" className="form-label">Hologram</label>
                    <SelectSwingTagHologram initialId={newJerseyData.authenticity_hologram_text} callback={(id) => {onSelect("authenticity_hologram_text", id)}}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="hologram_text" className="form-label">Hologram code</label>
                    <input className="form-control" name="hologram_text"  value={newJerseyData.hologram_text } onChange={onChange} />
                </div>
            </div>
        </div>	

        <hr/>


    </div>
    )
}

export default CheckJerseyData