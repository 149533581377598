

const actionTypes =  
{
    CLEAR_EVERYTHING: 'CLEAR_EVERYTHING',
    SET_CONFIGS: 'SET_CONFIGS',
    SET_TOKEN: 'SET_TOKEN',
    SET_USER: 'SET_USER',
    SET_FILTER_FOR_CHECKS: 'SET_FILTER_FOR_CHECKS',
    SET_CHECKS: 'SET_CHECKS',
    SET_FILTERED_CHECKS: 'SET_FILTERED_CHECKS',
    CLEAR_PARAMS: 'CLEAR_PARAMS',
    SET_SEARCH_BAR_FILTER: 'SET_SEARCH_BAR_FILTER',
    SET_SEARCH_BAR_FILTER_CHECK_ID: 'SET_SEARCH_BAR_FILTER_CHECK_ID',
    SET_SUMMARY: 'SET_SUMMARY',
    SET_ENABLE_DELETE_ITEM_FROM_TABLE: 'SET_ENABLE_DELETE_ITEM_FROM_TABLE',
    SET_CURRENT_CHECK_PRODUCT: 'SET_CURRENT_CHECK_PRODUCT',
    SET_CURRENT_CHECK_JERSEY: 'SET_CURRENT_CHECK_JERSEY',
    //photos
    SET_NEW_CHECK_PHOTOS: 'SET_NEW_CHECK_PHOTOS',

}

export default actionTypes;
