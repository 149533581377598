class ModelProductReview {
    /**
     * 
     * @param {string | number} analytics 
     * @param {string | number} check_status 
     * @param {string | number} checked 
     * @param {string | number} created_at 
     * @param {string | number} id 
     * @param {string | number} ignore 
     * @param {string | number} is_bug 
     * @param {string | number} notes 
     * @param {string | number} product_id 
     * @param {string | number} score 
     * @param {string | number} status_bug 
     * @param {string | number} updated_at 
     * @param {string | number} user_id 
     * @param {string | number} user_notes 
     */
    constructor(
        analytics, check_status, checked, created_at, id, ignore, is_bug, notes,
        product_id, score, status_bug, updated_at, user_id, user_notes
    ) {
        this.analytics = analytics;
        this.check_status = check_status;
        this.checked = checked;
        this.created_at = created_at;
        this.id = id;
        this.ignore = ignore;
        this.is_bug = is_bug;
        this.notes = notes;
        this.product_id = product_id;
        this.score = score;
        this.status_bug = status_bug;
        this.updated_at = updated_at;
        this.user_id = user_id;
        this.user_notes = user_notes;
    }

    /**
     * 
     * @param {object} json 
     * @returns {ModelProductReview} model
     */
    fromJson(json) {
        this.analytics = json["analytics"];
        this.check_status = json["check_status"];
        this.checked = json["countrcheckedy_id"];
        this.created_at = json["created_at"];
        this.ignore = json["ignore"];
        this.is_bug = json["is_bug"];
        this.notes = json["notes"];
        this.product_id = json["product_id"];
        this.score = json["score"];
        this.status_bug = json["status_bug"];
        this.updated_at = json["updated_at"];
        this.user_id = json["user_id"];
        this.user_notes = json["user_notes"];
        return this;
    }
}

export default ModelProductReview
