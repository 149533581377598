import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
import { DEFAULT_OPTIONS } from '../../store/defaultOptions';

export const SelectCareLabel = ({initialId, callback}) => {
    const configs = DEFAULT_OPTIONS.care_label
    const [selectedValue, setSelectedValue] = useState(initialId);

    function onSelect(event) {
        let value = event.target.value
        setSelectedValue(value)
        callback(value) 
    }
    
    return (
        <div>
            <p>{selectedValue}</p>
            <select className="form-select" value={initialId ?? configs[0].id} onChange={onSelect} name='care_label'>
            { configs.map((e) => {return (  <option key={e.id} value={e.id}>{e.name}</option>  )}) }
            </select>
        </div>
    )
}

export const SelectCareLabelMarkings = ({initialId, callback}) => {
    const configs = DEFAULT_OPTIONS.care_label_markings
    const [selectedValue, setSelectedValue] = useState(initialId);

    function onSelect(event) {
        let value = event.target.value
        setSelectedValue(value)
        callback(value)
    }
    
    return (
        <div>
            <select className="form-select" value={initialId ?? configs[0].id} onChange={onSelect} name='care_label'>
            { configs.map((e) => {return (  <option key={e.id} value={e.id}>{e.name}</option>  )}) }
            </select>
        </div>
    )
}

export const SelectCareLabelQrCode = ({initialId, callback}) => {
    const configs = DEFAULT_OPTIONS.qr_code
    const [selectedValue, setSelectedValue] = useState(initialId);

    function onSelect(event) {
        let value = event.target.value
        setSelectedValue(value)
        callback(value)
    }
    
    return (
        <div>
            <select className="form-select" value={selectedValue ?? configs[0].id} onChange={onSelect} name='care_label'>
            { configs.map((e) => {return (  <option key={e.id} value={e.id}>{e.name}</option>  )}) }
            </select>
        </div>
    )
}
