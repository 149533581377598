import React, { useEffect, useRef, useState } from 'react';
import { IconUploadFile } from '../../components/Icons';
import ModelPhoto from '../../model/ModelPhoto';
import { DEFAULT_OPTIONS } from '../../store/defaultOptions';
import CheckBox from '../../components/CheckBox';
import EachImage from './components/EachImage';
import { useDispatch, useSelector } from 'react-redux';
import { setNewCheckPhotos } from '../../store/actions';

const PageAddItem = ({photoCallBack}) => {
    const alreadyAddedPhotos = useSelector(state => state.newCheckPhotos)
    const dispatch = useDispatch()
    const ref = useRef(null)
    const refInput = useRef(null)
    const [araeaColor, setaraeaColor] = useState("transparent")
    const [loadingImg, setloadingImg] = useState(false)
    const [img, setimg] = useState(null)
    //
    const [allFiles, setAllFiles] = useState([])

    useEffect(() => {
        if (alreadyAddedPhotos) { setAllFiles(alreadyAddedPhotos) }
    }, [])

    useEffect(() => {
        if (ref) {
            ref.current.addEventListener("dragover", handleDragOver)
            ref.current.addEventListener("dragleave", handleDragLeave)
            ref.current.addEventListener("drop", handleDrop)
        }
    }, [])

    function handleDragLeave(e) {
        e.preventDefault()
        setaraeaColor("transparent") 
    }

    function handleDragOver(e) {
        if (!img) {

            e.preventDefault()
            e.stopPropagation();
            setaraeaColor("rgb(210, 210, 210)")
            setimg("e")
        }
    }

    function handleDrop(e) {
        if (!img) {

            e.preventDefault()
            setaraeaColor("transparent")
            setloadingImg(true)
            
            const {files} = e.dataTransfer;
            
            getFiles(files)
            setloadingImg(false)
        }
    }

    function onClickDropArea() {
        if (!img) {
            refInput.current.click();
        }
    }

    function handleInput(event) {
        const files = event.target.files;
        if (files.length > 0) {
            getFiles(files)
        }
    }

    /**
     * This function looks a little convoluted.
     * 
     * We need to pay attention to each photoId when adding to the {allFiles} object.
     */
    async function getFiles(files) {        
        try {
            // lets get all urls from the files in form of list
            const objectUrls = Array.from(files).map((file) => {return {url: URL.createObjectURL(file), photoData: file}});
            const lista = []
            
            // lets get the [newIndex]. If [allFiles] isnt null, the new index must be the next integer after the last element of this list
            let newIndex
            if (allFiles.length > 0) {
                newIndex = allFiles[allFiles.length - 1].photoId
            } else {
                newIndex = 0
            }

            // lets create a newPhoto {ModelPhoto} object for each photo
            objectUrls.forEach((o, index) => {
                let newPhoto = new ModelPhoto(newIndex + index + 1, o.url, o.photoData,  []  )
                lista.push(newPhoto)
            })
            setAllFiles((prevImages) => [...prevImages, ...lista]);
        } catch (error) {
            console.error('Error creating object URLs:', error);
        }
    }

    /** the callback returs the {ModelPhoto} with the correct names */
    function eachImageCallBack(modelPhoto) {
    }

    /** when the user decides to remove one of the images already added */
    function onClickRemove(photoId) {
        setAllFiles(prev => prev.filter((f) => f.photoId !== photoId))
    }

    function onClickCancel() {
        window.history.back();
    }

    function onClickSave() {
        dispatch(setNewCheckPhotos(allFiles))
        window.history.back();
    }

    return (
        <div style={{width: "100%", height: "100%"}}> 
            <h2 className='flex center'>Add new images below</h2>
            
            <div className='grid g2-columns'>
                {
                    allFiles.length > 0  && 
                    
                    allFiles.map((i) => {
                        return (
                            <div className='each-image-with-button'>
                                <EachImage imageModel={i} callback={eachImageCallBack}/>
                                <button className='btn btn-primary' onClick={() => { onClickRemove(i.photoId) }}>Remove this image</button>
                            </div>
                        )
                    })
                }
            </div>

            <div style={{height: 120}}>
                
            </div>
            
            <div className='page-add-item-bottom'>
                <div className="drop-photo-area clicable" ref={ref} style={{background: araeaColor }} onClick={onClickDropArea}>
                    <input ref={refInput} type="file" onInput={handleInput} multiple />
                    {
                        <div className='drop-photo-area-center'>
                            <IconUploadFile size={25}/>
                            <p style={{textAlign: "center", marginTop: 5, paddingInline: 25}}>Click or drag file to this area to upload</p>
                        </div>
                    }
                </div>

                <button className='btn btn-secondary' onClick={onClickCancel} >Cancel</button>
                <button className='btn btn-primary'   onClick={onClickSave}   >Save</button>
            </div>
        </div>
    )
}

export default PageAddItem