class ModelSummary {

    /**
     * Model of the summary
     * @param {number | string} accuracy 
     * @param {number} totalRecords 
     * @param {number} totalUsersRecords 
     * @param {number} activeBugs 
     */
    constructor(accuracy, totalRecords, totalUsersRecords, activeBugs ) {
        this.accuracy = accuracy;
        this.totalRecords = totalRecords;
        this.totalUsersRecords = totalUsersRecords;
        this.activeBugs = activeBugs;
    } 

    /**
     * 
     * @param {object} json 
     * @returns {ModelSummary} model
     */
    fromJson(json) {
        this.accuracy = json["accuracy"] ?? json["accurary"];
        this.totalRecords = json["totalRecords"];
        this.totalUsersRecords = json["totalUsersRecords"];
        this.activeBugs = json["activeBugs"];
        return this;
    }
}

export default ModelSummary
