const TagColors = ({status}) => {
    let color = null
    let fontcolor = null
    if (status) {
        if (status.toLowerCase().includes("authentic")) { color = "var(--color-authentic)"; fontcolor = "black"}
        if (status.toLowerCase().includes("inconclusive")) { color = "var(--color-inconclusive)"; fontcolor = "white"}
        if (status.toLowerCase().includes("likely")) { color = "var(--color-likely)"; fontcolor = "white"}
        if (status.toLowerCase().includes("suspected")) { color = "var(--color-suspected)"; fontcolor = "white"}
        if (status.toLowerCase().includes("counterfeit")) { color = "var(--color-counterfeit)"; fontcolor = "white"}
        if (status.toLowerCase().includes("ot rate")) { color = "grey"; fontcolor = "white"}
        if (status.toLowerCase().includes("ignore")) { color = "grey"; fontcolor = "white"}
    }
    return (
        <div className='badge px-2' style={{background: color, color: fontcolor, width: "100%"}}>
            {status}
        </div>
    )
}

export default TagColors