import React from 'react';
// eslint-disable-next-line no-unused-vars
import ModelCheck from '../../../model/ModelCheck';

const HomeScoreCalculations = ({checkData}) => {
    
    /**
     * @type {ModelCheck}
     */
    const check = checkData


    return (
        <div>
            <h5>Score calculations</h5>
            <table className="table">
                <thead>
                    <tr>
                        <th>Element</th>
                        <th>Explanation</th>
                    </tr>
                </thead>
                <tbody>

                    <tr>
                        <td>Key Indicators</td>
                        <td>
                            { check.analytics_trait.keyIndicators.triggers &&
                            <div className='flex-container'>
                                <div>Triggers</div>
                                <div>{
                                    check.analytics_trait.keyIndicators.triggers.map((e) => <p>{e}</p>)
                                    }</div>
                            </div>
                            }
                        </td>
                    </tr>

                    <tr>
                        <td>Swing tag</td>
                        <td>
                            { check.analytics_trait.swingTag.codeNotFound &&
                            <div className='flex-container'>
                                <div>Code Not Found</div>
                                <div>{check.analytics_trait.swingTag.codeNotFound}</div>
                            </div>
                            }
                            
                            { check.analytics_trait.swingTag.codeCounterfeit &&
                            <div className='flex-container'>
                                <div>Code Counterfeit</div>
                                <div>{check.analytics_trait.swingTag.codeCounterfeit}</div>
                                </div>
                            }
                            { check.analytics_trait.swingTag.codeInconclusive &&
                            <div className='flex-container'>
                                <div>Code Inconclusive</div>
                                <div>{check.analytics_trait.swingTag.codeInconclusive}</div>
                            </div>
                            }
                            { check.analytics_trait.swingTag.swing_tag &&
                            <div className='flex-container'>
                                <div>Swing tag</div>
                                <div>{check.analytics_trait.swingTag.swing_tag}</div>
                            </div>
                            }
                            { check.analytics_trait.swingTag.yearMessing &&
                            <div className='flex-container'>
                                <div>Year Missing</div>
                                <div>{check.analytics_trait.swingTag.yearMessing}</div>
                            </div>
                            }
                            { check.analytics_trait.swingTag.doesNotMatchStyleCode &&
                            <div className='flex-container'>
                                <div>Does Not Match Style Code</div>
                                <div>{check.analytics_trait.swingTag.doesNotMatchStyleCode}</div>
                            </div>
                            }
                            { check.analytics_trait.swingTag.barcode_type &&
                            <div className='flex-container'>
                                <div>Barcode type</div>
                                <div>{check.analytics_trait.swingTag.barcode_type}</div>
                            </div>
                            }
                            { check.analytics_trait.swingTag.noBarcode &&
                            <div className='flex-container'>
                                <div>No Barcode</div>
                                <div>{check.analytics_trait.swingTag.noBarcode}</div>
                            </div>
                            }
                            { check.analytics_trait.swingTag.upc_barcode_problem &&
                            <div className='flex-container'>
                                <div>UPC barcode problem</div>
                                <div>{check.analytics_trait.swingTag.upc_barcode_problem}</div>
                            </div>
                            }
                            { check.analytics_trait.swingTag.upcBarcodeNotFoundInLabel &&
                            <div className='flex-container'>
                                <div>UPC Barcod eNot Found In Label</div>
                                <div>{check.analytics_trait.swingTag.upcBarcodeNotFoundInLabel}</div>
                            </div>
                            }
                            { check.analytics_trait.swingTag.otherTeam &&
                            <div className='flex-container'>
                                <div>Other team</div>
                                <div>{check.analytics_trait.swingTag.otherTeam}</div>
                            </div>
                            }
                            { check.analytics_trait.swingTag.otherBrand &&
                            <div className='flex-container'>
                                <div>Other brand</div>
                                <div>{check.analytics_trait.swingTag.otherBrand}</div>
                            </div>                         
                            }
                        </td>
                    </tr>

                    <tr>
                        <td>Care Label</td>
                        <td>
                            { check.analytics_trait.careLabel.codeNotFound &&
                            <div className='flex-container'>
                                <div>Code Not Found</div>
                                <div>{check.analytics_trait.careLabel.codeNotFound}</div>
                            </div>
                            }
                            { check.analytics_trait.careLabel.codeCounterfeit &&
                            <div className='flex-container'>
                                <div>Code Counterfeit</div>
                                <div>{check.analytics_trait.careLabel.codeCounterfeit}</div>
                                </div>
                            }
                            { check.analytics_trait.careLabel.codeInconclusive &&
                            <div className='flex-container'>
                                <div>Code Inconclusive</div>
                                <div>{check.analytics_trait.careLabel.codeInconclusive}</div>
                            </div>
                            }
                            { check.analytics_trait.careLabel.care_label &&
                            <div className='flex-container'>
                                <div>Care label</div>
                                <div>{check.analytics_trait.careLabel.care_label}</div>
                            </div>
                            }
                            { check.analytics_trait.careLabel.care_label_markings &&
                            <div className='flex-container'>
                                <div>Care Label Markings</div>
                                <div>{check.analytics_trait.careLabel.care_label_markings}</div>
                            </div>
                            }
                            { check.analytics_trait.careLabel.noCountry &&
                            <div className='flex-container'>
                                <div>No Country</div>
                                <div>{check.analytics_trait.careLabel.noCountry}</div>
                            </div>
                            }
                            { check.analytics_trait.careLabel.qr_code_problem &&
                            <div className='flex-container'>
                                <div>QR code problem</div>
                                <div>{check.analytics_trait.careLabel.qr_code_problem}</div>
                            </div>
                            }
                            { check.analytics_trait.careLabel.countryNeckDoesNotMatch &&
                            <div className='flex-container'>
                                <div>Country Does Not Match</div>
                                <div>{check.analytics_trait.careLabel.countryNeckDoesNotMatch}</div>
                            </div>
                            }
                            { check.analytics_trait.careLabel.brand_reference &&
                            <div className='flex-container'>
                                <div>Brand Reference</div>
                                <div>{check.analytics_trait.careLabel.brand_reference}</div>
                            </div>
                            }
                            { check.analytics_trait.careLabel.otherTeam &&
                            <div className='flex-container'>
                                <div>Other Team</div>
                                <div>{check.analytics_trait.careLabel.otherTeam}</div>
                            </div>
                            }
                            { check.analytics_trait.careLabel.otherBrand &&
                            <div className='flex-container'>
                                <div>Other Brand</div>
                                <div>{check.analytics_trait.careLabel.otherBrand}</div>
                            </div>
                            }
                            { check.analytics_trait.careLabel.styleCodeNotPresentInCareLabel &&
                            <div className='flex-container'>
                                <div>Style Code Not Present In Care Label</div>
                                <div>{check.analytics_trait.careLabel.styleCodeNotPresentInCareLabel}</div>
                            </div>
                            }
                        </td>
                    </tr>

                    {
                        (check.analytics_trait.neckLabel.otherTeam != null || check.analytics_trait.neckLabel.otherBrand != null) && 
                   
                        <tr>
                            <td>Neck Label</td>
                            <td>
                                { check.analytics_trait.neckLabel.otherTeam &&
                                <div className='flex-container'>
                                    <div>Other Team</div>
                                    <div>{check.analytics_trait.neckLabel.otherTeam}</div>
                                </div>
                                }
                                { check.analytics_trait.neckLabel.otherBrand &&
                                <div className='flex-container'>
                                    <div>Other Brand</div>
                                    <div>{check.analytics_trait.neckLabel.otherBrand}</div>
                                    </div>
                                }
                            </td>
                        </tr>
                     }
                    <tr>
                        <td>Authenticity Marking</td>
                        <td>
                            { check.analytics_trait.authenticityMarking.codeNotFound &&
                            <div className='flex-container'>
                                <div>Code Not Found</div>
                                <div>{check.analytics_trait.authenticityMarking.codeNotFound}</div>
                            </div>
                            }
                            { check.analytics_trait.authenticityMarking.codeCounterfeit &&
                            <div className='flex-container'>
                                <div>Code Counterfeit</div>
                                <div>{check.analytics_trait.authenticityMarking.codeCounterfeit}</div>
                            </div>
                            }
                            { check.analytics_trait.authenticityMarking.codeInconclusive &&
                            <div className='flex-container'>
                                <div>Code Inconclusive</div>
                                <div>{check.analytics_trait.authenticityMarking.codeInconclusive}</div>
                            </div>
                            }
                            { check.analytics_trait.authenticityMarking.authenticity_marking &&
                            <div className='flex-container'>
                                <div>Authenticity marking</div>
                                <div>{check.analytics_trait.authenticityMarking.authenticity_marking}</div>
                            </div>
                            }
                            { check.analytics_trait.authenticityMarking.scoreFix &&
                            <div className='flex-container'>
                                <div>Score Fix</div>
                                <div>{check.analytics_trait.authenticityMarking.scoreFix}</div>
                            </div>
                            }
                            { check.analytics_trait.authenticityMarking.dueToAnotherAuthenticityNumber &&
                            <div className='flex-container'>
                                <div>Due To Another Authenticity Number</div>
                                <div>{check.analytics_trait.authenticityMarking.dueToAnotherAuthenticityNumber}</div>
                            </div>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>Purchase Place</td>
                        <td>
                            { check.analytics_trait.purchasePlace.purchase_place &&
                            <div className='flex-container'>
                                <div>Purchase place</div>
                                <div>{check.analytics_trait.purchasePlace.purchase_place}</div>
                            </div>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>Style Code Label</td>
                        <td>
                            { check.analytics_trait.styleCodeLabel.codeNotFound &&
                            <div className='flex-container'>
                                <div>Code Not Found</div>
                                <div>{check.analytics_trait.styleCodeLabel.codeNotFound}</div>
                            </div>
                            }
                            { check.analytics_trait.styleCodeLabel.codeCounterfeit &&
                            <div className='flex-container'>
                                <div>Code Counterfeit</div>
                                <div>{check.analytics_trait.styleCodeLabel.codeCounterfeit}</div>
                                </div>
                            }
                            { check.analytics_trait.styleCodeLabel.codeInconclusive &&
                            <div className='flex-container'>
                                <div>Code Inconclusive</div>
                                <div>{check.analytics_trait.styleCodeLabel.codeInconclusive}</div>
                            </div>
                            }
                            { check.analytics_trait.styleCodeLabel.style_code_label &&
                            <div className='flex-container'>
                                <div>Style code label</div>
                                <div>{check.analytics_trait.styleCodeLabel.style_code_label}</div>
                            </div>
                            }
                            { check.analytics_trait.styleCodeLabel.doesNotMatchStyleCode &&
                            <div className='flex-container'>
                                <div>Does Not Match Style Code</div>
                                <div>{check.analytics_trait.styleCodeLabel.doesNotMatchStyleCode}</div>
                            </div>
                            }
                            { check.analytics_trait.styleCodeLabel.otherTeam &&
                            <div className='flex-container'>
                                <div>Other Team</div>
                                <div>{check.analytics_trait.styleCodeLabel.otherTeam}</div>
                            </div>
                            }
                            { check.analytics_trait.styleCodeLabel.otherBrand &&
                            <div className='flex-container'>
                                <div>Other Brand</div>
                                <div>{check.analytics_trait.styleCodeLabel.otherBrand}</div>
                            </div>
                            }
                            { check.analytics_trait.styleCodeLabel.styleCodeNotFound &&
                            <div className='flex-container'>
                                <div>Style Code Not Found</div>
                                <div>{check.analytics_trait.styleCodeLabel.styleCodeNotFound}</div>
                            </div>
                            }
                            { check.analytics_trait.styleCodeLabel.mismatchesInStyleCode &&
                            <div className='flex-container'>
                                <div>Mismatches In Style Code</div>
                                <div>{check.analytics_trait.styleCodeLabel.mismatchesInStyleCode}</div>
                            </div>
                            }
                        </td>
                    </tr>
           
                </tbody>
            
            </table>
        </div>
    )
}

export default HomeScoreCalculations
