import React, { useState } from 'react';
import { IconCheck } from '../../../components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { setEnableDeleteItemFromTable } from '../../../store/actions';
// eslint-disable-next-line no-unused-vars
import ModelCheck from '../../../model/ModelCheck';
import Apis from '../../../api/api';
import LoadingView from '../../../components/Loading';

const TableOptions = ({onClickedAddNewItem}) => {
    const dispatch = useDispatch()
    const enableDelete = useSelector(state => state.enableDeleteItemFromTable)
    const [loading, setloading] = useState(false)

    /** @type {ModelCheck} */
    const check = useSelector(state => state.checkProduct)

    function onClickEnableDelete() {
        dispatch(setEnableDeleteItemFromTable(!enableDelete))
    }

    async function onClickExportToClipboard() {
        setloading(true)

        let wholeDB = await Apis.getWholeDataToExportToClipboard()
        const response = wholeDB;
        let cb = 'ID\tScore\tStatus\tBrand\tTeam\tSeason\tCategory\tNeck Label Country';
        cb += '\tCare Label Style Code Number\tCL QR Code Presence\tCL QR Code';
        cb += '\tCare Label Country\tStyle Code Label Presence\tStyle Code Label #';
        cb += '\tSwing Tags Style Code #\tSwing Tags UPC #\tSecurity Feature';
        cb += '\tSecurity Feature #\tnotes\tphoto link\n';
        response.products.forEach((p) => {
          cb += `${p.id}\t${p.score}\t${p.checkStatus}\t${p.brandName}\t${p.teamName}`;
          cb += `\t${p.year}\t${p.categoryName}\t${p.neckLabelCountryName}`;
          cb += `\t${p.careLabelStyleCode}\t${p.qrCode}\t${p.qrCodeNumber}`;
          cb += `\t${p.careLabelCountryName}\t${p.styleCodeLabel}\t${p.styleCode}`;
          cb += `\t${p.swingTagStyleCode}\t${p.upcBarcode}\t${p.authenticityMarking}`;
          cb += `\t${p.authenticityNumber}\t${p.notes || ''}\t${((p.photos || [])[0] || {}).url || ''}`;
          cb += '\n';
        });

        navigator.clipboard.writeText(cb)
        setloading(false)

        alert("Copied")
    }

    return (
        <div className='flex'>

{/* add new item bnutton  */}
            <button className='btn btn-primary mx-2' onClick={onClickedAddNewItem}>Add new item</button>


{/* more options button  */}
            {
                loading ? <div className='px-3'>
                    <LoadingView size={10}/>
                </div> :

                <div className="dropdown show">
                    <span className="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        More Options
                    </span>

                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <div onClick={onClickExportToClipboard}>Export to clipboard</div>
                        <div className='flex' onClick={onClickEnableDelete}>
                    
                            Enable delete
                                {enableDelete && <div style={{marginLeft: 5}}>
                                    <IconCheck />
                                </div> }
                        
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default TableOptions

