import React, { useEffect, useState } from 'react';
import Apis from '../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { clearParams, setConfigs, setSearchBar, setSearchBarCheckId, setSummary, setToken, setUser, updateValor } from '../../store/actions';
import { useNavigate } from 'react-router-dom';
import LoadingView from '../../components/Loading';
import { IconK } from '../../components/Icons';
import { functionGetSummary } from '../../functions/getSummary';

const PageLogin = () => {
    const [loading, setloading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isLoggedIn, setIsLoggedIn] = useState(false)

        
    const [form, setform] = useState({  
        username: "",
        password: "",
    })


    /** lets see if the token is in the localstorage, if it is, we dont need to log in */
    useEffect(() => {
        dispatch(setSearchBarCheckId(null))
        dispatch(setSearchBar(null))
        loadUserEmailFromLocalStorage()
    }, [])

    let emailuser = localStorage.getItem('user_email')

    function loadUserEmailFromLocalStorage() {
        if (emailuser != null && emailuser.length > 0) {

            setTimeout(() => {
                let e = {}
            e.target = {}
            e.target.name = "username"
            e.target.value = emailuser

            hadleInput(e)
            }, 100);

        
        }
    }

    /** this will log in automatically */
    async function navigateDirectly() {
        try {
            setloading(true);
            dispatch(clearParams(null))
            // lets get the tables configs, team nams, brands, etc
            let configs = await Apis.getConfigs();
            dispatch(setConfigs(configs))
            
            // then lets get the user and save it to the stote
            let user = await Apis.getUser()
            dispatch(setUser(user))
            setloading(false);
            navigate("checks")
        } catch (error) {
            console.log("deu erro")
            localStorage.setItem('token', null)
            setIsLoggedIn(false)
        }
    }


    function hadleInput(e) {
        const {name, value} = e.target  
        setform({
            ...form, [name]: value
        })
    }
    
    async function handleSubmit(event) {
        setloading(true);
        event.preventDefault()
        try {
            // lets get the token and save it to store
            let login = await Apis.login(form.username, form.password);
            dispatch(setToken(login.token));   

            //lets save the user email to the local storage, so that the user wont need to input it every time they wanna log in
            localStorage.setItem('user_email', form.username);

            // lets get the tables configs, team nams, brands, etc
            let configs = await Apis.getConfigs();
            dispatch(setConfigs(configs))
            
            // then lets get the user and save it to the stote
            let user = await Apis.getUser()
            dispatch(setUser(user))

            // then lets get the summary numbers
            // let summary = await Apis.getSummary()
            let summary = await functionGetSummary()
            dispatch(setSummary(summary))

            // then lets clear all the previous params from persisted store
            dispatch(clearParams(null))

            // then lets finally navigate
            navigate("checks")
        } catch (error) {
            alert(error)
        } finally {
            setloading(false)
        }
    }


    return (
        <div style={{height: "100vh"}}>

            {
                loading 
                ? <LoadingView/>
                : <div className='center '> 
                    <form  className='form card' onSubmit={handleSubmit}>
                        <div className='flex center my-3'>
                            <IconK/>
                            <h5 className='mx-3'>Login</h5>
                        </div>
                        <div className='mt-3'>
                            <label className='form-label' htmlFor='username'>Username</label>
                            <input className='form-control' type="" name="username" onInput={hadleInput} value={form.username}/>
                        </div>
                        <div className='mt-3'>
                            <label className='form-label' htmlFor='password'>Password</label>
                            <input className='form-control' type="" name="password" onInput={hadleInput} />
                        </div>

                        <button className='mt-3 btn btn-primary'>Login</button>
                    </form>
                </div>
            }

        </div>
    )
}

export default PageLogin