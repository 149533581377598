import React, { useState } from 'react';
// eslint-disable-next-line no-unused-vars
import ModelCheck from '../../../model/ModelCheck';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/**
 * 
 * @param {ModelCheck} check 
 * @returns 
 */
const HomeGallery = ({isNew}) => {

	const [showDialog, setShowDialog] = useState(false)
	const check = useSelector(state => state.checkProduct)
	const navigate = useNavigate()
	const photos = check.photos

	const [selectedImage, setselectedImage] = useState(0)

	function imgSrc(id, photo) {
		const urlPhoto = "https://admin.kitlegit.com/storage?"
		return `${urlPhoto}product_id=${id}&file=${photo}`
	}

	function onClickImage(index) {
		setselectedImage(index)
	}

	function onClickAddImages(index) {
		setShowDialog(!showDialog)
	}

	function addImageCallBack(photoIndexes, photoURL) {
		setShowDialog(!showDialog)
	}


    return (
        <div>

			{
				photos[selectedImage] == null 
				
				? <div  className="img-square-big">
					<button className='btn btn-primary' onClick={onClickAddImages}>Add images</button>
				</div>
				
				: <img className="img-square-big" src={imgSrc(photos[selectedImage].product_id, photos[selectedImage].file_name)} alt=""/>
			}
			
			<div className="img-grid clicable">
				{
					photos.map((p, index) => {
						return <img className="XX" onClick={() => { onClickImage(index) }} key={index} src={ imgSrc(p.product_id, p.file_name) } alt=""/>
					})
				}
			</div>

			{
				// showDialog && <EachAddedPhoto onCliclClose={onClickAddImages} callback={addImageCallBack}/>
			}
        </div>
    )
}

export default HomeGallery