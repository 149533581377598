import { combineReducers } from "redux";
import actionTypes from "./types";

const initialState = {
    TOKEN: localStorage.getItem('token') || null,
    CONFIGS: null,
    ALL_COUNTRIES: null,
    ALL_BRANDS: null,
    ALL_CHECK_STATUS: null,
    ALL_TEAMS : null,
    ALL_PURCHASE_PLACES : null,
    ALL_SPORTS: null,
    ALL_CONDITIONS: null,
    ALL_CATEGORIES: null,
    ALL_SEASONS: null,
    ALL_USERS: null,
    ALL_CHECKS: null,
    ALL_TEAM_TYPES: null,
    ALL_TYPES: null,
    ALL_SIZES: null,
    FILTERED_CHECKS: null,
    USER_NAME: null,
    USER_SURNAME: null,
    USER_EMAIL: null,
}


const clearEverythingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_EVERYTHING:
            return {
                ...state, TOKEN: action.payload
            }
    
        default:
            return state;
    }
}

const tokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TOKEN:
            localStorage.setItem('token', action.payload)
            return {
                ...state, TOKEN: action.payload
            }
    
        default:
            return state;
    }
}

const configsReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SET_CONFIGS:

            /**
             * Organize the sports with the id inside the array, then, return the array
             * instead of an object like it used to be
             * @returns {Array} with sports
             */
            function getSports() {
                let lista = []
                for (var key in action.payload.sports) {
                    if (action.payload.sports.hasOwnProperty(key)) {
                        var sport = action.payload.sports[key];
                        lista.push({
                            id: key,
                            name: sport.name,
                            slug: sport.slug,
                            available: sport.available,
                            minCredits: sport.minCredits,
                            maxCredits: sport.maxCredits
                        })
                    }
                }
                return lista;
            }

            /**
             * Organize the chech status 
             * @returns {Array} with sports
             */
            function checkStatuses() {
                let lista = [
                    {id: 1,  name: "Authentic | Counterfeit"},
                    {id: 2,  name: "All but Authentic and Counterfeit"},
                    {id: 5,  name: "Counterfeit"},
                    {id: 10, name: "Suspected"},
                    {id: 15, name: "Inconclusive"},
                    {id: 20, name: "Likely Authentic"},
                    {id: 25, name: "Authentic"},
                ]
                return lista
            }

            /**
             * Organize
             * @returns {Array}
             */
            function conditions() {
                let lista = [
                    {id: null,  name: "Select"},
                    {id: 1,  name: "New"},
                    {id: 2,  name: "Used"},
                ]
                return lista
            }
            /**
             * Organize
             * @returns {Array}
             */
            function categories() {
                let lista = [
                    {id: null,  name: "Select"},
                ]
                action.payload.categories.forEach((c) => lista.push(c))
                return lista
            }
            /**
             * Organize
             * @returns {Array}
             */
            function alltypes() {
                let lista = [
                    {id: 0,  name: "Club"},
                    {id: 1,  name: "National"},
                ]
                return lista
            }
            /**
             * Organize
             * @returns {Array}
             */
            function allSizes() {
                let lista = [{id: null, name: "Select"}]
                action.payload.sizes.forEach((s) => {lista.push(s)})
                return lista
            }
            /**
             * Organize
             * @returns {Array}
             */
            function allSeasons() {
                let lista = []
                lista.push({id: null, name: "Select"})
                for (var i = 0; i < 75; i++) {
                    let year = (1950 + i)
                    lista.push({id: year,  name: `${year}`})
                }
                return lista
            }

            // here we will add the option "select" to the list, for when the value comes null
            let all_teams_fixed = action.payload.teams
            all_teams_fixed.unshift({id: -1, name: "Select"})

            let all_countries_fixed = action.payload.countries
            all_countries_fixed.unshift({id: -1, name: "Select"})

            let all_purchase_places_fixed = action.payload.purchase_places
            all_purchase_places_fixed.unshift({id: null, name: "Select"})

            return {
                ...state, 
                CONFIGS: action.payload,
                ALL_COUNTRIES: all_countries_fixed,
                ALL_BRANDS: action.payload.brands,
                ALL_CHECK_STATUS: checkStatuses(),
                ALL_TEAMS : all_teams_fixed,
                ALL_PURCHASE_PLACES : action.payload.purchase_places,
                ALL_SPORTS : getSports(),
                ALL_USERS : action.payload.users,
                ALL_CONDITIONS: conditions(),
                ALL_CATEGORIES: categories(),
                ALL_TYPES: alltypes(),
                ALL_TEAM_TYPES:action.payload.types,
                ALL_SIZES: allSizes(),
                ALL_SEASONS: allSeasons(),
            }
        default:
            return state;
    }
}


const filterForChecks = {
    per_page: 20,
    team_id: null,
    country_id: null,
    year: null,
    brand_id: null,
    sport: null,        // integer
    checkType: null,    // admin | user
    checkStatus: null,  // integer
    userReport: null,   // true | false
    userId: null,       // integer
    bug_status: null,   // string with the name of the bug status
    text: null,         // string with the text itself
    check_id: null,     // check_id itself
}


const filterForChecksReducer = (state = filterForChecks, action) => {
    switch (action.type) {
        case actionTypes.SET_FILTER_FOR_CHECKS:
            return {
                ...state,
                [action.payload.key]: action.payload.value
            }
        case actionTypes.CLEAR_PARAMS:
            return {
                per_page: 20,
                team_id: null,
                country_id: null,
                year: null,
                brand_id: null,
                sport: null,        
                checkType: null,    
                checkStatus: null,  
                userReport: null,   
                userId: null,       
                bug_status: null,
                text: null,   
                check_id: null, 
                season: null,  
            }
        default:
            return state
    }
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER:
            return  {
                ...state, 
                USER_NAME: action.payload.name,
                USER_SURNAME: action.payload.surname,
                USER_EMAIL: action.payload.email,
            }
    
        default:
            return state
    }
}
const initialSearchBar = ""
const checkSearchBarReducer = (state = initialSearchBar, action) => {
    switch (action.type) {
        case actionTypes.SET_SEARCH_BAR_FILTER: 
            return action.payload
        default:
            return state
    }
}

const checkSearchBarCehckIdReducer = (state = "", action) => {
    switch (action.type) {
        case actionTypes.SET_SEARCH_BAR_FILTER_CHECK_ID: 
            return action.payload
        default:
            return state
    }
}

const summaryReducer = (state = null, action) => {
    switch (action.type) {
        case actionTypes.SET_SUMMARY: 
            return action.payload
        default:
            return state
    }
}

const enableDeleteItemFromTableReducer = (state = false, action) => {
    switch (action.type) {
        case actionTypes.SET_ENABLE_DELETE_ITEM_FROM_TABLE: 
            return action.payload
        default:
            return state
    }
}

const currentCheckProductReducer = (state = null, action) => {
    switch (action.type) {
        case actionTypes.SET_CURRENT_CHECK_PRODUCT: 
            return action.payload
        default:
            return state
    }
}

const currentCheckJerseyReducer = (state = null, action) => {
    switch (action.type) {
        case actionTypes.SET_CURRENT_CHECK_JERSEY: 
            return action.payload
        default:
            return state
    }
}

const newCheckPhotosReducer = (state = [], action) => {
    switch (action.type) {
        case actionTypes.SET_NEW_CHECK_PHOTOS: 
            return action.payload
        default:
            return state
    }
}


const rootReducer = combineReducers({
    token: tokenReducer,
    clearEverything: clearEverythingReducer,
    configs: configsReducer,
    user: userReducer,
    newCheckPhotos: newCheckPhotosReducer,
    checkProduct: currentCheckProductReducer,
    checkJersey: currentCheckJerseyReducer,
    checkParams: filterForChecksReducer,
    checkSearchBar: checkSearchBarReducer,
    checkSearchBarCheckId: checkSearchBarCehckIdReducer,
    summary: summaryReducer,
    enableDeleteItemFromTable: enableDeleteItemFromTableReducer,
})

export default rootReducer;