export const DEFAULT_OPTIONS = {
    neck_label: [
        { id: 1, name: 'Visible', score: 0 },
        { id: 2, name: 'faded', score: -50 },
        { id: 3, name: 'Removed', score: -25 },
        { id: 4, name: 'missing', score: -100 },
        { id: 5, name: 'notSure', score: -25 },
    ],
    care_label: [
        { id: 1, name: 'Visible', score: 0 },
        { id: 2, name: 'faded', score: -50 },
        { id: 3, name: 'Removed', score: -25 },
        { id: 4, name: 'missing', score: -100 },
        { id: 5, name: 'notSure', score: -25 },
    ],
    care_label_markings: [
      { id: null, name: 'Select', score: 0 }, { id: "true", name: 'Present', score: 0 }, { id: "false", name: 'Not Present', score: 0 }
    ],
    style_code_label: [
      { id: null, name: 'Select', score: 0 },
      { id: 1, name: 'Visible', score: 0 },
      { id: 2, name: 'Faded', score: -50 },
      { id: 3, name: 'Removed', score: -25 },
      { id: 4, name: 'Missing', score: -100 },
      { id: 5, name: 'Not Sure', score: -25 },
    ],
    authenticity_marking: [
      { id: null, name: 'Select', score: 0 },
      { id: 1, name: 'Visible', score: 0 },
      { id: 2, name: 'Faded', score: -50 },
      { id: 3, name: 'Removed', score: -25 },
      { id: 4, name: 'Missing', score: 0 },
      { id: 5, name: 'Not Sure', score: -25 },
    ],
    swing_tag: [
      { id: null, name: 'Select', score: 0 },
      { id: 1, name: 'Visible', score: 0 },
      { id: 2, name: 'Third Party Tag', score: 0 },
      { id: 3, name: 'Sports Performance Tag', score: 0 },
      { id: 4, name: 'Missing', score: 0 },
    ],
    crest_quality: [
      { id: 1, name: 'Poor', score: -5 }, { id: 2, name: 'Average', score: -2.5 }, { id: 3, name: 'Good', score: 0 }
    ],
    stitching_quality: [
      { id: 1, name: 'Poor', score: -5 }, { id: 2, name: 'Average', score: -2.5 }, { id: 3, name: 'Good', score: 0 }
    ],
    peeling: [
      { id: 1, name: 'None', score: 0 }, { id: 2, name: 'Some', score: -5 }, { id: 3, name: 'Significant', score: -10 }
    ],
    upc_barcode_problem: [
      { id: null, name: 'Select', score: 0 },
      { id: 1, name: 'Visible', score: 0 },
      { id: 2, name: 'Does Not Scan', score: 0 },
      { id: 3, name: 'Not Readable', score: -50 }
    ],
    barcode_type: [
      // { id: 1, name: 'sticker', score: 0 }, // no loger asked
      // { id: 2, name: 'prePrinted', score: -50 }, // no longer asked
      { id: null, name: 'Select' },
      { id: 1, name: 'Visible' },
      { id: 3, name: 'Missing', score: -75 },
      { id: 4, name: 'Removed' },
      { id: 5, name: 'Faded' },
      { id: 6, name: 'Originally Not Present' },
    ],
    authenticity_hologram_text: [
      { id: null, name: 'Select', score: 0 },
      { id: 1, name: 'Yes', score: 0 },
      { id: 2, name: 'No', score: -75 },
      { id: 3, name: 'Not Sure', score: -25 }
    ],
    swing_tag_terms_type: [
      { id: 1, name: 'Specifics', score: 0 }, { id: 2, name: 'Generics', score: -100 }
    ],
    qr_code: [
      { id: null, name: 'Select', score: 0 }, { id: 1, name: 'Present', score: 0 }, { id: 2, name: 'Not Present', score: 0 }
    ],
    qr_code_problem: [
      { id: 1, name: 'Visible', score: 0 },
      { id: 2, name: 'Does Not Scan', score: 0 },
      { id: 3, name: 'Not Readable', score: 0 },
    ],
    authenticity_qr_code: [
      { id: 1, name: 'Visible', score: 0 },
      { id: 2, name: 'Faded', score: 0 },
      { id: 3, name: 'Removed', score: 0 },
      { id: 4, name: 'Missing', score: 0 },
      { id: 5, name: 'Not Sure', score: 0 },
    ],
    authenticity_qr_tag: [
      { id: 1, name: 'Visible', score: 0 },
      { id: 2, name: 'Faded', score: 0 },
      { id: 3, name: 'Removed', score: 0 },
      { id: 4, name: 'Missing', score: 0 },
      { id: 5, name: 'Not Sure', score: 0 },
    ],
    condition: [
      { id: 1, name: 'New' },
      { id: 2, name: 'Used' },
    ],

    photoNames: [
      { id: 1, name: 'shirtFront', title: "Front of shirt" },
      { id: 2, name: 'shirtBack', title: "Back of shirt" },
      { id: 3, name: 'neckLabel', title: "Neck label" },
      { id: 4, name: 'Crest / Brand Close-up', title: "Crest/Close-ups" },
      { id: 5, name: 'swingTags', title: "Swing tags" },
      { id: 7, name: 'mainCareLabel', title: "Main care label" },
      { id: 8, name: 'styleCodeLabel', title: "Style code label" },
      { id: 12, name: 'other', title: "Other" },
      { id: 18, name: 'mainAuthenticityFeature', title: "Main authenticity feature" },
      { id: 19, name: 'hologram', title: "Hologram" },
    ],

    bugStatus: [
      { id: null, name: 'Select'},
      { id: 1, name: 'No bug'},
      { id: 2, name: 'Active'},
      { id: 3, name: 'Needs Review'},
      { id: 4, name: 'Needs Testing'},
      { id: 5, name: 'Resolved'},
    ],

    checkStatus: [
      { id: 5, name: 'Counterfeit'},
      { id: 10, name: 'Suspected'},
      { id: 15, name: 'Inconclusive'},
      { id: 20, name: 'Likely Authentic'},
      { id: 25, name: 'Authentic'},
    ],

    adminCheckStatus: [
      { id: 0, name: 'Not Rated'},
      { id: "ignore", name: 'Ignore'},
      { id: 5, name: 'Counterfeit'},
      { id: 10, name: 'Suspected'},
      { id: 15, name: 'Inconclusive'},
      { id: 20, name: 'Likely Authentic'},
      { id: 25, name: 'Authentic'},
    ],

    checkTypes: [
      { id: null, name: 'User: Automatic'},
      { id: 0, name: 'Admin: Counterfeit'},
      { id: 100, name: 'Admin: Authentic'},
    ],

    versionsDropdown: [
      { id: null, name: 'Select'},
      { id: "player", name: 'Player version'},
      { id: "stadium", name: 'Stadium version'},
    ]

    

  }