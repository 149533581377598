import React from 'react';
import { IconCheckboxChecked, IconCheckboxUnchecked } from './Icons';

const CheckBox = ({title, value, onClick}) => {
    return (
        <div className='checkbox clicable' onClick={onClick}>
            <div className='checkbox-img'>
                {
                    value ? <IconCheckboxChecked/> : <IconCheckboxUnchecked/>
                }
            </div>
            <label className="checkbox-label" >{ title }</label>
        </div>
    ) 
}

export default CheckBox