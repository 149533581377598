import React, { useState } from 'react';
// eslint-disable-next-line no-unused-vars
import ModelCheck from '../../../model/ModelCheck';

const HomeCategoryScores = ({checkData}) => {
    /**
     * @type {ModelCheck}
     */
    let check = checkData

    return (
        <div>
            <h5>Category scores</h5>
            
            <div className="grid g4-columns">
                <div className="mb-3">
                    <label className="form-label" htmlFor="x">Overall score</label>
                    <input className='form-control' value={check.score} disabled/>
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="x">Key indicators</label>
                    <input className='form-control' value={check.analytics.keyIndicators} disabled/>
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="x">Care label</label>
                    <input className='form-control' value={check.analytics.careLabel} disabled/>
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="x">Style code label</label>
                    <input className='form-control' value={check.analytics.styleCodeLabel} disabled/>
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="x">Security features</label>
                    <input className='form-control' value={check.analytics.authenticityMarking} disabled/>
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="x">Swing tags</label>
                    <input className='form-control' value={check.analytics.swingTag} disabled/>
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="x">Purchase place</label>
                    <input className='form-control' value={check.analytics.purchasePlace} disabled/>
                </div>
            
            </div>
        </div>
    )
}

export default HomeCategoryScores