import axios from 'axios'
import ModelCheckList from '../model/ModelChecksList';
import ModelSummary from '../model/ModelSummary';
import ModelCheck from '../model/ModelCheck';
import ModelPhoto from '../model/ModelPhoto';

const DNS_ADMIN = "https://admin.kitlegit.com/api/v1"
const DNS = "https://kitlegit.com/api/v1"
const DNS_NO_API = "https://kitlegit.com"

// dsn de teste
// const DNS_ADMIN = "http://reolon.ddns.net:3333/api/v1"
// const DNS = "http://reolon.ddns.net:3333/api/v1"
// const DNS_NO_API = "http://reolon.ddns.net:3333"

let bearerToken = "";

class Apis {


    static headers() {
        return {
            "Content-Type": "application/json",
            "Accept": "*/*",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + localStorage.getItem("token")
        }
    }

    /**
     * Login
     * @param {String} email 
     * @param {String} password 
     * @returns {Promise<any>} returns an obj with {token: string, refreshToken: string, expires_at: Date() }
     */
    static async login(email, password) {
        let body = { email: email, password: password }
        return new Promise((resolve, reject) => {
            axios.post(`${DNS_ADMIN}/auth/login`, body, {headers: this.headers()})
            .then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    /**
     * Gets the user info
     * @returns {Promise<any>} 
     */
    static async getUser() {
        return new Promise((resolve, reject) => {
            axios.get(`${DNS}/auth/me`, {headers: this.headers()})
            .then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    
    static async getConfigs() {
        try {
            let dataconfigs = await axios.get(`${DNS}/sidetables`, {headers: this.headers()})
            let databrands = await axios.get(`${DNS}/sidetables/brands`, {headers: this.headers()})
            let datausers = await axios.get(`${DNS}/user`, {headers: this.headers()})
            let datacountries = await axios.get(`${DNS}/sidetables/countries`, {headers: this.headers()})
            let configs = dataconfigs.data
            let brands = databrands.data
            let users = datausers.data
            let countries = datacountries.data
            configs.brands = brands.brands
            configs.users = users
            configs.countries = countries.countries
            localStorage.setItem('configs', JSON.stringify(configs))
            return configs;
        } catch (error) {
            throw new Error(`Error in getConfigs() :: ${error}`)
        }
    }
    
    /**
     * Gets the summary numbers
     * @returns {ModelSummary} {accurary, totalRecords, }
     */
    static async getSummary() {
        try {
            console.log("indo");
            let dataconfigs = await axios.get(`${DNS}/products/summary`, {headers: this.headers()})
            console.log(dataconfigs);
            return new ModelSummary().fromJson(dataconfigs.data);
        } catch (error) {
            throw new Error(`Error getting summary :: ${error}`)
        }
    }

    /**
     * Gets the whoel database
     */
    static async getWholeDataToExportToClipboard() {
        try {
            let dataconfigs = await axios.get(`${DNS}/products/analysis`, {headers: this.headers()})
            return dataconfigs.data
        } catch (error) {
            throw new Error(`Error getting getWholeDataToExportToClipboard :: ${error}`)
        }
    }

    /**
     * Gets a single check by its id
     * @param {String | number} id
     * @returns {ModelCheck}
     */
    static async getCheck(id) {
        try {
            console.log("getting check", id)
            let result = await axios.get(`${DNS}/products/${id}`, {headers: this.headers()})
            console.log("getting check")
            console.log(result)
            return new ModelCheck().fromJson(result.data)
        } catch (error) {
            throw new Error(`Error getting check :: ${error}`)
        }
    }

    /**
     *     per_page: 20,
    team_id: null,
    country_id: null,
    year: null,
    brand_id: null,
    sport: null,        // integer
    checkType: null,    // admin | user
    checkStatus: null,  // integer
    userReport: null,   // string
    userId: null,       // integer
     */

    /**
     * Gets all the checks
     * @param {number} page - initially is 1
     * @param {*} team_id 
     * @param {*} country_id 
     * @param {*} year //manufacture
     * @param {*} brand_id 
     * @param {*} sport 
     * @param {*} checkType // admin | user
     * @param {*} checkStatus 
     * @param {*} userReport 
     * @param {*} userId 
     * @param {*} bug_status 
     * @param {*} text 
     * @param {*} check_id 
     * @param {*} season 
     * @returns {Promise<Array<ModelCheckList>>} - obj = {checks: checks, lastPage: res.last_page}
    */
    static async getAllChecks(page, team_id, country_id, year, brand_id, sport, checkType, checkStatus, userReport, userId, bug_status, text, check_id, season) {

        console.log("fetching")
        console.log("text", text)
        console.log("check_id", check_id)

        let paramsStr = ""
        if (team_id != null) { 
            paramsStr = paramsStr + "&team_id=" + team_id
        }
        if (country_id != null) { 
            paramsStr = paramsStr + "&country_id=" + country_id
        }
        if (year != null) { 
            paramsStr = paramsStr + "&year=" + year
        }
        if (season != null) { 
            paramsStr = paramsStr + "&season=" + season
        }
        if (brand_id != null) { 
            paramsStr = paramsStr + "&brand_id=" + brand_id
        }
        if (sport != null) { 
            paramsStr = paramsStr + "&sport=" + sport
        }
        if (checkType != null) { 
            paramsStr = paramsStr + "&checkType=" + checkType
        }
        if (checkStatus != null) { 
            paramsStr = paramsStr + "&checkStatus=" + checkStatus
        }
        if (bug_status != null) { 
            paramsStr = paramsStr + "&bug_status=" + bug_status
        }
        if (userId != null) { 
            paramsStr = paramsStr + "&userId=" + userId
        }
        if (userReport != null) { 
            paramsStr = paramsStr + "&userReport=" + userReport
        } else {
            paramsStr = paramsStr + "&userReport=any"
        }
        if (text != null) { 
            paramsStr = paramsStr + "&text=" + text
        }
        if (check_id != null) { 
            paramsStr = paramsStr + "&check_id=" + check_id
        }

        console.log(`${DNS}/products?page=${page}&per_page=20${paramsStr}`)


        return new Promise((resolve, reject) => {
            // axios.get(`${DNS}/sidetables/index`, {headers: this.headers()}) 
            // axios.get(`${DNS}/products?page=${page}&per_page=20&userReport=any`, {headers: this.headers()})
            axios.get(`${DNS}/products?page=${page}&per_page=20${paramsStr}`, {headers: this.headers()})
            .then(res => {
                let checks = []
                for (var i = 0; i < res.data.data.length; i++) {
                    const item = res.data.data[i]
                    checks.push( new ModelCheckList().fromJson(item))
                }
                let result = {checks: checks, lastPage: res.data.last_page}
                resolve(result)
            }).catch(err => {
                reject(err)
            })
        })
    }

    /**
     * Creates a new product
     * @param {ModelCheck} checkModel 
     * @returns {object} {product_id: 400}
     */
    static async createNewCheck(checkModel) {
        try {
            let res =  await axios.patch(`${DNS}/products/0`, checkModel, {headers: this.headers()})
            return res.data;
        } catch (error) {
            alert(`Erro no :: createNewCheck = ${error}`)
            
        }
    }

    static async updateCheck(checkId,  checkModel) {
        try {
            await axios.patch(`${DNS}/products/${checkId}`,  checkModel, {headers: this.headers()})
        } catch (error) {
            alert(`Erro no :: updateCheck = ${error}`)
            
        }
    }

    /**
     * Used to update a jersey information on a check
     * @param {*} checkId 
     * @param {*} jerseyModel 
     * @returns 
     */
    static async updateCheckJersey(checkId, jerseyModel) {
        try {
            let res =  await axios.patch(`${DNS}/jerseys/${checkId}`, jerseyModel, {headers: this.headers()})
            // let res =  await axios.patch(`http://localhost:8000/kitlegit`, jerseyModel, {headers: this.headers()})
            return res.data;
        } catch (error) {
            alert(`Erro no :: updateCheckJersey = ${error}`)
            
        }
    }
    
    static async downloadPDF(check_certificate) {
        const apiUrl = `${DNS_NO_API}/storage/certificates/${check_certificate}.pdf`; // Replace with your API endpoint
        const downloadLink = document.createElement('a');
        
        fetch(apiUrl)
            .then(response => response.blob())
            .then(blob => {
                // Create a Blob from the response data
                const url = window.URL.createObjectURL(blob);
            
                // Set up the download link
                downloadLink.href = url;
                downloadLink.download = `${check_certificate}.pdf`; // Replace with the desired file name
            
                // Append the download link to the document
                document.body.appendChild(downloadLink);
            
                // Trigger the click event on the download link
                downloadLink.click();
            
                // Clean up: remove the download link and revoke the Blob URL
                document.body.removeChild(downloadLink);
                window.URL.revokeObjectURL(url);
                
            }).catch(error => console.error('Error downloading PDF file:', error));             

    }

    /**
     * 
     * @param {string | number} checkId 
     * @param {boolean} useForFutureReference 
     * @returns ;
     */
    static async sendUseForFutureReference(checkId, useForFutureReference) {
        try {
            if (useForFutureReference == null) {
                throw new Error("You must provide a useForFutureReference")
            }
            let res =  await axios.patch(`${DNS}/products/codeasreference/${checkId}`, {removeAll: useForFutureReference}, {headers: this.headers()})
            // let res =  await axios.patch(`http://localhost:8000/kitlegit`, jerseyModel, {headers: this.headers()})
            return res.data;
        } catch (error) {
            alert(`Erro no :: useForFutureReference = ${error}`)
        }
    }

     /**
     * 
     * @param {number | string} checkId
     * @param {ModelPhoto[]} files 
     */
     static async sendPhotos(checkId, files) {
        const formData = new FormData();
        let allArrays = [];
        
        for (var i = 0; i < files.length; i++) {
            formData.append(`file_${i}`, files[i].photoData)
            formData.append(`file_mini_${i}`, files[i].photoData)

            let arrayItem = {
                id: null,// se arquivo novo, id = undefined | null | 0
                toDelete: false,
                photo_name_ids: files[i].names //number[] // o id do nome, se swing tag, se neck label, etc. pode ser 1 ou mais
            }

            allArrays.push(arrayItem)
        }
        
        const boundary = generateBoundary()

        const headers = new Headers();
        headers.append('Authorization', "Bearer " + localStorage.getItem("token"));
        // headers.append('Content-Type', `multipart/form-data; boundary=${boundary}`); 


        formData.append("info", JSON.stringify(allArrays))

        fetch(`${DNS}/photos/${checkId}`, {
            method: 'PATCH',
            headers: headers,
            body: formData,
        })
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('Error:', error);
                alert("Error sending photos: " + error)
                return error;
            });
    }

    /**
     * 
     * @param {string | number} checkId 
     * @returns 
     */
    static async deleteCheck(checkId) {
        try {
            let res =  await axios.delete(`${DNS}/products/${checkId}`, {headers: this.headers()})
            return res.data;
        } catch (error) {
            alert(`Erro no :: deleteCheck = ${error}`)
            
        }
    }

    /**
     * 
     * @param {string | number} checkId 
     * @param {string} bugStatus 
     * @returns 
     */
    static async sendBugStatus(checkId, bugStatus) {
        try {
            let res =  await axios.post(`${DNS}/productsreview/${checkId}`, {bug_status: bugStatus}, {headers: this.headers()})
            return res.data;
        } catch (error) {
            alert(`Erro no :: sendBugStatus = ${error}`)
        }
    }

    /**
     * 
     * @param {string | number} checkId 
     * @returns 
     */
    static async getBugStatus(checkId) {
        try {
            let res =  await axios.get(`${DNS}/productsreview/${checkId}`, {headers: this.headers()})
            return res.data;
        } catch (error) {
            alert(`Erro no :: sendBugStatus = ${error}`)
        }
    }

    /**
     * 
     * @param {string | number} checkId 
     * @param {string} notes 
     * @returns 
     */
    static async sendNotes(checkId, notes) {
        try {
            let res =  await axios.post(`${DNS}/productsreview/${checkId}`, {notes: notes}, {headers: this.headers()})
            return res.data;
        } catch (error) {
            alert(`Erro no :: sendNotes = ${error}`)
        }
    }
    /**
     * 
     * @param {string | number} checkId 
     * @returns 
     */
    static async getNotes(checkId) {
        try {
            let res =  await axios.get(`${DNS}/productsreview/${checkId}`, {headers: this.headers()})
            return res.data;
        } catch (error) {
            alert(`Erro no :: getNotes = ${error}`)
        }
    }

    /**
     * 
     * @param {string | number} checkId 
     * @param {string} notes 
     * @returns 
     */
    static async sendAdminStatus(checkId, check_status_id) {
        let body = {check_status: check_status_id}
        if (check_status_id.toString().toLowerCase().includes("ignore")) {
            body.ignore = true
            body.check_status = (check_status_id)
        } else {
            body.check_status = parseInt(check_status_id)
        }
        try {
            let res =  await axios.post(`${DNS}/productsreview/${checkId}`, body, {headers: this.headers()})
            return res.data;
        } catch (error) {
            alert(`Erro no :: sendAdminStatus = ${error}`)
        }
    }
    /**
     * 
     * @param {string | number} checkId 
     * @param {number} check_status_id 5 or 25
     * @returns 
     */
    static async sendSaveAsAuthenticOrCounterfeit(checkId, check_status_id) {
        try {
            let res =  await axios.patch(`${DNS}/products/updatecheckstatus/${checkId}`, {check_status: check_status_id}, {headers: this.headers()})
            return res.data;
        } catch (error) {
            alert(`Erro no :: sendSaveAsAuthenticOrCounterfeit = ${error}`)
        }
    }

}

export function generateBoundary() {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let boundary = '----';
  
    for (let i = 0; i < 16; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      boundary += characters.charAt(randomIndex);
    }
  
    return boundary;
  }


export default Apis