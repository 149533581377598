import React, { useEffect, useState } from 'react';
import SelectTeam from '../../../components/selectors/SelectTeam';
import SelectSport from '../../../components/selectors/SelectSport';
import SelectSeason from '../../../components/selectors/SelectSeason';
import SelectBrand from '../../../components/selectors/SelectBrand';
import SelectType from '../../../components/selectors/SelectType';
import SelectCondition from '../../../components/selectors/SelectCondition';
import SelectCategory from '../../../components/selectors/SelectCategory';
import SelectPurchasePlace from '../../../components/selectors/SelectPurchasePlace';
// eslint-disable-next-line no-unused-vars
import ModelCheck, { ModelJersey } from '../../../model/ModelCheck';
import { SelectNeckLabel } from '../../../components/selectors/NeckLabel';
import SelectCountry from '../../../components/selectors/Countries';
import { SelectCareLabel, SelectCareLabelMarkings, SelectCareLabelQrCode } from '../../../components/selectors/CareLabel';
import { SelectStyleCodeLabel } from '../../../components/selectors/StyleCodeLabel';
import { SelectSecurityFeatures } from '../../../components/selectors/SecurityFeatures';
import { SelectSwingTag, SelectSwingTagBarcode, SelectSwingTagHologram } from '../../../components/selectors/SwingTag';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentCheckProduct } from '../../../store/actions';
import SelectTeamType from '../../../components/selectors/SelectTeamType';
import CheckBox from '../../../components/CheckBox';
import SelectVersion from '../../../components/selectors/SelectVersion';

const CheckProductData = () => {

    const dispatch = useDispatch()
    
    /**
     * @type {ModelCheck}
    */
    const checkProduct = useSelector(state => (state.checkProduct))
    const [yearCheck, setYearCheck] = useState(checkProduct.year_check)

    function onSelectProductInfo(name, value) {
        let newProd = checkProduct
        newProd[name] = value
        dispatch(setCurrentCheckProduct(newProd))
    }

    function onClickYearCheck() {
        let newProd = checkProduct
        newProd.year_check = !checkProduct.year_check
        setYearCheck(newProd.year_check)
        dispatch(setCurrentCheckProduct(newProd))
    }

    return (
    <div>
        <div>
            <h5>Shirt information</h5>
            <div className="grid g4-columns">
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Sport</label>
                    <SelectSport initialId={checkProduct.sport} callback={(id) => {onSelectProductInfo("sport", id)}} />
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Team</label>
                    <SelectTeam initialId={checkProduct.team_id} callback={(id) => {onSelectProductInfo("team_id", id)}}  />
                </div>
                <div className="mb-3">
                    <label htmlFor="year_manufactured" className="form-label">Manuf. Year</label>
                    <SelectSeason initialId={parseInt(checkProduct.year_manufactured)} callback={(id) => {onSelectProductInfo("year_manufactured", id)}}  />
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Season</label>
                    <SelectSeason initialId={parseInt(checkProduct.year)} callback={(id) => {onSelectProductInfo("year", id)}}  />
                </div>
                <div/>
                <div/>
                <div/>
                <div className="mb-3" style={{}}>
                    <CheckBox value={yearCheck} title={"Year check"} onClick={onClickYearCheck} />
                </div>
            </div>
            <div className="grid g4-columns">
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Brand</label>
                    <SelectBrand initialId={checkProduct.brand_id} callback={(id) => {onSelectProductInfo("brand_id", id)}} />
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Type</label>
                    <SelectTeamType initialId={checkProduct.team_type} callback={(id) => {onSelectProductInfo("team_type", id)}} />
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Category</label>
                    <SelectCategory initialId={checkProduct.category_id} callback={(id) => {onSelectProductInfo("category_id", id)}} />
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Version</label>
                    <SelectVersion initialId={checkProduct.version} callback={(id) => {onSelectProductInfo("version", id)}} />
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Condition</label>
                    <SelectCondition initialId={checkProduct.condition} callback={(id) => {onSelectProductInfo("condition", id)}} />
                </div>
                <div className="mb-3">
                    <label  htmlFor="exampleFormControlInput1" className="form-label">Purchase place</label>
                    <SelectPurchasePlace initialId={checkProduct.purchase_place_id} callback={(id) => {onSelectProductInfo("purchase_place_id", id)}} />
                </div>

            </div>
        </div>	

        <hr/>



    </div>
    )
}

export default CheckProductData