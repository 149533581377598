import React, { useState } from 'react';
import { DEFAULT_OPTIONS } from '../../store/defaultOptions';

export const SelectStatus = ({initialId, callback, isAdmin}) => {
    const configs = isAdmin ? DEFAULT_OPTIONS.adminCheckStatus : DEFAULT_OPTIONS.checkStatus
    const [selectedValue, setSelectedValue] = useState(initialId);

    function onSelect(event) {
        let value = event.target.value
        setSelectedValue(value)
        callback(value)
    }
    
    return (
        <div>
            <select className="form-select" value={initialId ?? configs[0].id} onChange={onSelect} name='team_id'>
            {
                configs.map((e) => {
                    return (
                        <option key={e.id} value={e.id}>{e.name}</option>
                    )
                })
            }
            </select>
        </div>
    )
}

export default SelectStatus