import React, { useState } from 'react';
import { DEFAULT_OPTIONS } from '../../store/defaultOptions';

export const SelectCheckTypes = ({initialId, callback}) => {
    const configs = DEFAULT_OPTIONS.checkTypes
    const [selectedValue, setSelectedValue] = useState(initialId);

    function onSelect(event) {
        let value = event.target.value
        setSelectedValue(value)
        callback(value)
    }
    
    return (
        <div>
            <select className="form-select" value={initialId == null ? configs[0].id : initialId > 0 && initialId < 100 ?  configs[0].id : initialId} onChange={onSelect} name='check_types'>
            {
                configs.map((e) => {
                    return (
                        <option key={e.id} value={e.id}>{e.name}</option>
                    )
                })
            }
            </select>
        </div>
    )
}

export default SelectCheckTypes