import React from 'react';
import { IconK } from './Icons';

const LoadingView = ({size}) => {
    return (
        <div className='center'> 
            <div className='loading-icon'>
                <IconK size={size}/>
            </div>
        </div>
    )
}

export default LoadingView