import React from 'react';
import { IconArrowDown } from '../../../components/Icons';
// eslint-disable-next-line no-unused-vars
import ModelCheck from '../../../model/ModelCheck';
import { DateFormatter } from '../../../components/DateFormat';
import TagColors from '../../../components/TagColors';
import SelectStatus from '../../../components/selectors/SelectStatus';
import Apis from '../../../api/api';

/**
 * 
 * @param {ModelCheck} checks 
 * @returns 
 */ 
const HomeShirtTitle = ({check}) => { 


    return (
        <div className='flex-container'>
            <div>
                <div className='flex-container-start'>
                    <h5 className='fflex-1'>{check.team_name}, </h5>
                    <h5 className='fflex-1'>{check.brand_name}</h5>
                    <div className='spacer-hor-16'> </div>
                    <div >
                    <TagColors status={check.status_name} />
                    </div>
                </div>

                <div className='flex font-grey'>
                    <p>Check ID: {check.certificate_code},  {DateFormatter(check.created_at)}</p>
                </div>

            </div>


            
        </div>
    )
}

export default HomeShirtTitle