import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import ModelSummary from '../../../model/ModelSummary';
import { functionGetSummary } from '../../../functions/getSummary';
import { setSummary } from '../../../store/actions';

import ic_refresh from "../../../assets/ic_refresh.svg"
import LoadingView from '../../../components/Loading';

const TableSummary = () => {
    
    const dispatch = useDispatch()

    /**
     * @type {ModelSummary}
     */
    const summary = useSelector((state) => state.summary)

    const [loading, setloading] = useState(false)


    async function refresh() {
        setloading(true)
        let summary = await functionGetSummary()
        dispatch(setSummary(summary))
        setloading(false)
    }



    return (
        <>

        {
        
        <div className='grid g5-columns' >
            <div className='card' >
                <label className="" >Total records</label>
                { !loading ? <h2>{summary.totalRecords}</h2> : <h2>...</h2>}
            </div>
            <div className='card' >
                <label className="" >User checks</label>
                { !loading ? <h2>{summary.totalUsersRecords}</h2> : <h2>...</h2>}
            </div>
            <div className='card' >
                <label className="" >Accuracy r.</label>
                { !loading ? <h2>{summary.accuracy}</h2> : <h2>...</h2>}
            </div>
            <div className='card' >
                <label className="" >Active bugs</label>
                { !loading ? <h2>{summary.activeBugs}</h2> : <h2>...</h2>}
            </div>
            <div >
               <img onClick={refresh} style={{height: 26, width: 26,margin: 5, cursor: "pointer"}} src={ic_refresh} alt=""/>
            </div>
        </div>
        }
        </>
        
    )
}

export default TableSummary