

/**
 * 
 * @param {string} date 
 * @returns {string} formatted
 */
export const DateFormatter = (date, longMonth = true, showTimezone = true) => {
    const currentDate = new Date(date);
    const options = { 
        year: 'numeric', 
        month: longMonth ? 'long' : "numeric", 
        day: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit'
    };

    if (showTimezone) {
        options.timeZoneName = "short"
    }
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);
    return formattedDate
}
export const DateFormatterOnlyDate = (date) => {
    const currentDate = new Date(date);
    const options = { 
        year: 'numeric', 
        month: "short", 
        day: 'numeric', 
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);
    return formattedDate
}

export const DateFormatterOnlyTime = (date) => {
    const currentDate = new Date(date);
    const options = { 
        hour: '2-digit', 
        minute: '2-digit'
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);
    return formattedDate
}