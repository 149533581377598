import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import { useSelector } from 'react-redux';
 
const SelectCountry = ({initialId, callback}) => {
    const configs = useSelector((state) => state.configs)
    const [selectedValue, setSelectedValue] = useState(initialId);

    useEffect(() => {
        setSelectedValue(parseInt(initialId))
    }, [])

    function onSelect(event) { 
        let value = event.target.value
        setSelectedValue(value)
        callback(value)
    }
    
    return (
        <div>
            <select className="form-select" value={initialId ?? configs.ALL_COUNTRIES[0].id} onChange={onSelect} name='neck_label'>
            { configs.ALL_COUNTRIES.map((e) => {return (  <option key={e.id} value={e.id}>{e.name}</option>  )}) }
            </select>
        </div>
    )
}



export default SelectCountry