import HomeAddNote from "./components/HomeAddNote";
import HomeCategoryScores from "./components/HomeCategoryScores";
import HomeGallery from "./components/HomeGallery";
import HomeHeader from "../../components/Header";
import HomeScoreCalculations from "./components/HomeScoreCalculations";
import CheckProductData from "./components/HomeShirtData";
import HomeShirtReviewBugs from "./components/HomeShirtReviewBugs";
import HomeShirtTitle from "./components/HomeShirtTitle";
import { useEffect, useState } from "react";
import LoadingView from "../../components/Loading";
import Apis from "../../api/api";
import ModelCheck, { ModelJersey, fixJerseyJson } from "../../model/ModelCheck";
import { useDispatch, useSelector } from "react-redux";
import {  setCurrentCheckJersey, setCurrentCheckProduct, setNewCheckPhotos } from "../../store/actions";
import CheckJerseyData from "./components/CheckJerseyData";
import CheckGalleryNew from "./components/CheckGalleryNew";
import CheckBox from "../../components/CheckBox";
import SelectCheckTypes from "../../components/selectors/SelectCheckTypes.js";
import AnalysisBottomBar from "./components/AnalysisBottomBar.js";

function PageCheckAnalysis() {
	const checkId = window.location.search.includes("id=") ? window.location.search.split("id=")[1] : null

	const [loading, setloading] = useState(true)
	const [valUseForFutureReference, setvalUseForFutureReference] = useState(false)
	const [checkScore, setcheckScore] = useState(null)
	const dispatch = useDispatch()


	/** @type {ModelCheck} */  
	const check = useSelector((state) => state.checkProduct)

	/** @type {ModelJersey} */
	const checkJersey = useSelector((state) => state.checkJersey)

	const newCheckPhotos = useSelector((state) => state.newCheckPhotos)

	// every time we load this page, we fecth the data for this jersey
	useEffect(() => { fetchCheck() }, [])
 
	/**
	 * Then we check if we are editing a jersey or creating a new check
	 */ 
	async function fetchCheck() {
		console.log("loadnig")
		setloading(true)  
 
		// lets get and set the check 
		if (checkId == null) { 
			let check = new ModelCheck()
			dispatch(setCurrentCheckProduct(check))
			dispatch(setCurrentCheckJersey(check.jersey))
			// dispatch(setNewCheckPhotos([])) // lets zero out the photos that had been added before
		} else {
			let check = await Apis.getCheck(checkId) 
			dispatch(setCurrentCheckProduct(check)) 
			dispatch(setCurrentCheckJersey(check.jersey)) 
		}

		setcheckScore(check.score ??  null)
		setloading(false) 
	}  

	/**
	 * Saves the current check product and jersey to the database
	 */
	async function onClickSave() {
		setloading(true)
		if (checkId == null) {
			let res = await Apis.createNewCheck(check)
			let justGottenCheckId = res.product_id
			await Apis.updateCheckJersey(justGottenCheckId, fixJerseyJson(checkJersey))
			await Apis.sendPhotos(justGottenCheckId, newCheckPhotos)
			await Apis.sendUseForFutureReference(justGottenCheckId, valUseForFutureReference)
		} else {
			await Apis.updateCheck(checkId, check)
			await Apis.updateCheckJersey(checkId, fixJerseyJson(checkJersey))
			await Apis.sendUseForFutureReference(checkId, valUseForFutureReference)
		}
		// then lets re feetch this same check to make sure everything has been saved
		await fetchCheck()
		setloading(false)
	}

	async function onClickSaveAsAuthentic() {
		setloading(true)
        await Apis.sendSaveAsAuthenticOrCounterfeit(checkId, 25)
		window.history.back();
		setloading(false)
    }

    async function onClickSaveAsCounterfeit() {
		setloading(true)
        await Apis.sendSaveAsAuthenticOrCounterfeit(checkId, 5)
		window.history.back();
		setloading(false)
    }
	
	function onSelectCheckType(e) {
		let val = e
		let checkData = check
		let checkJerseyData = checkJersey
		
		if ( val !== null && val !== "") {
			checkData.score       = parseInt(val)
			checkJerseyData.score = parseInt(val)
			dispatch(setCurrentCheckProduct(checkData)) 
			dispatch(setCurrentCheckJersey(checkJerseyData)) 
			setcheckScore(parseInt(val))
		} else {
			checkData.score       = null
			checkJerseyData.score = null
			dispatch(setCurrentCheckProduct(checkData)) 
			dispatch(setCurrentCheckJersey(checkJerseyData)) 
			setcheckScore("")
		}
	}

	function setUseForFutureReference(e) {
		setvalUseForFutureReference(!valUseForFutureReference)
	}

	return (
		<>
			{
				loading
				? <div style={{height: "100vh"}}><LoadingView/></div>
				: <div className="page">
				<HomeHeader />
				
				<div className="spacer-ver-16" />
				
				{
					checkId &&
					<HomeShirtTitle check={check} />
				}
	
				<div className="spacer-ver-16" />
	
				<div className="layout">
	
					<div className="layout-left">
						{
							checkId ? <HomeGallery/> : <CheckGalleryNew/>
						}
					</div>
	
					<div className="spacer-hor-40" />
	
					<div className="layout-right"> 
						<CheckProductData check={check} />
						<CheckJerseyData check={check} />  

{/* button Save */}
						<div className="flex-container-start">
							<button className="btn btn-primary" onClick={onClickSave}>Save information</button>

							<SelectCheckTypes initialId={check.score} callback={onSelectCheckType} />

						</div>
					</div>
					
				</div>
	
				<hr/>

				{
					checkId &&
					<div className="layout">
		
						<div className="layout-left">
							<HomeShirtReviewBugs  check={check} onSaveAsAuthentic={onClickSaveAsAuthentic} onSaveAsCounterfeit={onClickSaveAsCounterfeit} />
							<hr/>
							<HomeAddNote  check={check} />
						</div>
		
						<div className="spacer-hor-40" />
		
						<div className="layout-right">
							<HomeCategoryScores  checkData={check} />
							<div className="spacer-ver-32" />
							<HomeScoreCalculations  checkData={check} />
						</div>
						
					</div>
				}
				
	
			</div>

			
			}

{/* bottom bar  */}
			{
				!loading &&
							
				<div className="analysis-bottom-bar">
					<AnalysisBottomBar/>
				</div>
			}



			<div style={{marginBottom: 150}} />
		</>
	);
}

export default PageCheckAnalysis;
