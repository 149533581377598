import React, { useState } from "react";
import { DEFAULT_OPTIONS } from "../../store/defaultOptions";

export const SelectBugStatus = ({initialId, callback}) => {
    const configs = DEFAULT_OPTIONS.bugStatus
    const [selectedValue, setSelectedValue] = useState(initialId);

    function onSelect(event) {
        let value = event.target.value
        setSelectedValue(value)
        
        // in this case we have to send backl the name, thats why the value of the <select> element is a name 

        // if the name is "Select" it should send back null
        if (value.toLowerCase().includes("selec")) {
            callback(null) 
        } else {
            callback(value)
        }
    }
    
    return (
        <div>
            <select className="form-select" value={selectedValue ?? configs[0].name} onChange={onSelect} name='bug_status'>
            { configs.map((e) => {return (  <option key={e.id} value={e.name}>{e.name}</option>  )}) } 
            </select>
        </div>
    )
}