import React, { useEffect, useState } from 'react';
import CheckBox from '../../../components/CheckBox';
import { DEFAULT_OPTIONS } from '../../../store/defaultOptions';

const EachImage = ({imageModel, callback}) => {
    const config = DEFAULT_OPTIONS.photoNames
    const [selectedCheckbox, setSelectedCheckbox] = useState([])

    useEffect(() => {
        config.forEach(c => {
            if (imageModel.names.includes(c.id)) {
                setSelectedCheckbox(prev => [...prev, c.id])
            }
        })
    }, [])

    function addOrRemoveFileNameToFile(checkBoxId) {
        if (selectedCheckbox.includes(checkBoxId)) {
            setSelectedCheckbox(selectedCheckbox.filter(f => f !== checkBoxId))
            imageModel.names = selectedCheckbox.filter(f => f !== checkBoxId)
            callback(imageModel)
        } else {
            setSelectedCheckbox(prev => [...prev, checkBoxId])
            let newList = selectedCheckbox
            newList.push(checkBoxId)
            imageModel.names = newList
            callback(imageModel)
        }
    }

    return (
        <div className='each-image-page'>

            <img src={ imageModel.url } alt="img here" />

            <div className=''>
                {
                    config.map((e) => {
                        return (
                            <CheckBox value={selectedCheckbox.includes(e.id)} title={e.title} onClick={() => {addOrRemoveFileNameToFile(e.id)}} />
                        )
                    })
                }
            </div>

        </div>
    )
}

export default EachImage