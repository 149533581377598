import React from 'react';
import { IconArrowDown, IconGear, IconK } from './Icons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const HomeHeader = () => {
    const navigate = useNavigate()

    const user = useSelector(state => state.user)
    
    const avatar = "https://cdn4.vectorstock.com/i/1000x1000/15/28/user-sign-icon-person-symbol-vector-2861528.jpg"

    function onClickLogOut() {
        localStorage.setItem('token', null)
        localStorage.clear()
        navigate("/")
    }


    return (
        <div>
            <div className='flex'>
                <div className='flex'>
                    <IconK />
                    <div className='spacer-hor-16'> </div>
                    <h4>KitLegit</h4>
                </div>

                <div className='spacer-hor-40'></div>
                <div className='spacer-hor-40'></div>

                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container-fluid">
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0  font-grey">
                                <li className="nav-item flex">
                                    <IconGear/>
                                    <a className="nav-link active" aria-current="page" href="#">Settings</a>
                                </li>
                                <li className="nav-item flex">
                                    <IconGear/>
                                    <a className="nav-link active" aria-current="page" href="#">Users</a>
                                </li>
                                <li className="nav-item flex">
                                    <IconGear/>
                                    <a className="nav-link active" aria-current="page" href="#">Checks</a>
                                </li>
                                <li className="nav-item flex">
                                    <IconGear/>
                                    <a className="nav-link active" aria-current="page" href="#">Credits</a>
                                </li>
                            
                            </ul>

                        </div>
                    </div>
                </nav>

                <div className='flex-1'></div>


                <div className='flex'>
                    <img className='avatar' src={avatar} alt=""/>
                    <div className='spacer-hor-16'></div>
                    <div className='user-card-name-text'>
                        <h6>{user.USER_NAME} {user.USER_SURNAME}</h6>
                        <p className='font-grey'>KitLegit Ltd</p>
                    </div>
                    <div className='spacer-hor-16'></div>

                    <div className="dropdown">
                        <span className="" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <IconArrowDown/>
                        </span>

                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <div onClick={onClickLogOut}>Log out</div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default HomeHeader