import React, { useState } from "react";
import HomeHeader from "../../components/Header";
import TableSummary from "./components/TableSummary";
import TableOptions from "./components/TableOptions";
import TableSearch from "./components/TableSearch";
import TableTable from "./components/TableTable";
import Apis from "../../api/api";
import PageAddItem from "../addItem/PageAddItem";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setNewCheckPhotos } from "../../store/actions";


function PageTable() {
    const [clickedAddItem, setclickedAddItem] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    function onClickedAddNewItem() {
        dispatch(setNewCheckPhotos([]))
        navigate("/analysis")
    }

	return (
		<div className="page">
			<HomeHeader />
			
			<div className="spacer-ver-16" />
			

            <div className="flex">
                <div style={{width: "50%"}}>
                    <TableSummary/>
                </div>

                <div className="flex-1" style={{width: "10%"}} />

                <div>
                    <TableOptions onClickedAddNewItem={onClickedAddNewItem}/>
                </div>
                    
            </div>
            
            <TableSearch/>

            <TableTable/>

		</div>
	);
}

export default PageTable;
