import React, { useState } from 'react';
import { IconSearch } from '../../../components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { clearParams, setFiltersForTableOfChecks, setSearchBar, setSearchBarCheckId } from '../../../store/actions';
import { SelectBugStatus } from '../../../components/selectors/BugStatus';

const TableSearch = () => {
    const configs = useSelector((state) => state.configs)

    const checkParams = useSelector((state) => state.checkParams)
    const [typed, settyped] = useState("")
    const [typedCheckId, settypedCheckId] = useState("")

    const dispatch = useDispatch() 

    function onSelect(event) {
        dispatch(setFiltersForTableOfChecks({key: event.target.name, value: event.target.value}))
    }

    function onClickClearAll() {
        dispatch(clearParams(null))
    }

    function searchInput(event) {
        event.preventDefault()
        settyped(event.target.value)
    }

    function onClickSearch() {
        dispatch(setSearchBar(typed))
    }

    function searchInputCheckId(event) {
        event.preventDefault()
        settypedCheckId(event.target.value)
    }

    function onClickSearchCheckId() {
        dispatch(setSearchBarCheckId(typedCheckId))
    }

    async function handleBugStatus(bugstatus) {
        console.log("check aqui")
        dispatch(setFiltersForTableOfChecks({key: "bug_status", value: bugstatus}))
    }

    return (
        <>
        {
            configs &&
            <div>
            
            <div className="mt-3 flex-container">
                <div className="flex">
                    <input className='form-control' placeholder='Search...' onInput={searchInput} />
                    <span className="input-group-text" id="basic-addon1" onClick={onClickSearch}><IconSearch/></span>
                </div>
                <div className="flex">
                    <input className='form-control' placeholder='Check id' onInput={searchInputCheckId}/>
                    <span className="input-group-text" id="basic-addon1" onClick={onClickSearchCheckId}><IconSearch/></span>
                </div>
                <div className='flex me-5'>
                    <label className='me-2'>By user</label>
                    <div>
                        <select className="form-select"  onChange={onSelect} name='userId'>
                        {
                            configs.ALL_USERS.map((e) => {
                                return (
                                    <option key={e.id} value={e.id}>{e.name} {e.surname}</option>
                                )
                            })
                        }
                        </select>
                    </div>
                </div>
            </div>


            <div className='flex mt-3'>

                <div className='flex me-5'>
                    <label className='me-2'>Sport</label>
                    <div>
                        <select className="form-select"  onChange={onSelect} name='sport'>
                            {
                                configs.ALL_SPORTS.map((e) => {
                                    return (
                                        <option key={e.id} value={e.id}>{e.name}</option>
                                    )
                                })
                            }
                        </select>
    
                    </div>
                </div>

                <div className='flex me-5'>
                    <label className='me-2'>Team</label>
                    <div>
                        <select className="form-select"  onChange={onSelect} name='team_id'>
                        {
                            configs.ALL_TEAMS.map((e) => {
                                return (
                                    <option key={e.id} value={e.id}>{e.name}</option>
                                )
                            })
                        }
                        </select>
                    </div>
                </div>

                <div className='flex me-5'>
                    <label className='me-2'>Brand</label>
                    <div>
                        <select className="form-select"  onChange={onSelect} name='brand_id'>
                            {
                                configs.ALL_BRANDS.map((e) => {
                                    return (
                                        <option key={e.id} value={e.id}>{e.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>

                <div className='flex me-5'>
                    <label className='me-2'>Season</label>
                    <div>
                        <select className="form-select"  onChange={onSelect} name='season'>
                            {
                                configs.ALL_SEASONS.map((e) => {
                                    return (
                                        <option key={e.id} value={e.id}>{e.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>

                <div className='flex me-5'>
                    <label className='me-2'>Status</label>
                    <div>
                        <select className="form-select"  onChange={onSelect} name='checkStatus'>
                        {
                                configs.ALL_CHECK_STATUS.map((e) => {
                                    return (
                                        <option key={e.id} value={e.id}>{e.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                
                


                
            </div> 

            <div className='flex mt-3'>

                <div className='flex me-5'>
                    <label className='me-2'>User type</label>
                    <div>
                        <select className="form-select"  onChange={onSelect} name='checkType'>
                            <option value={null}>Both</option>
                            <option value="admin">Admin</option>
                            <option value="user">User</option>
                        </select>
                    </div>
                </div>

                <div className='flex me-5'>
                    <label className='me-2'>Reported</label>
                    <div>
                        <select className="form-select"  onChange={onSelect} name='userReport'> 
                            <option value={"any"}>-</option>
                            <option value={"with"}>Yes</option>
                            <option value={"without"}>No</option>
                        </select>
                    </div>
                </div>

                <div className='flex me-5'>
                    <label className='me-2'>Bug statuses</label>
                    <div>  
                        <SelectBugStatus initialId={null} callback={handleBugStatus}  />
                    </div>
                </div>

                

                <div>
                    <button className="btn btn-primary" onClick={onClickClearAll}>Clear all</button>
                </div>
            </div>
        </div>
        }
        </>
    )
}

export default TableSearch