import React, { useEffect, useState } from 'react';
import CheckBox from '../../../components/CheckBox';
import IC_webview from "../../../assets/ic_webview.png"
import IC_pdf from "../../../assets/ic_pdf.png"
import IC_share from "../../../assets/ic_share.png"
import IC_shareSVG from "../../../assets/ic_share_svg.svg"
import Apis from '../../../api/api';
import SelectStatus from '../../../components/selectors/SelectStatus';
import { useDispatch, useSelector } from 'react-redux';
import ModelCheck, { fixJerseyJson } from '../../../model/ModelCheck';
import { setCurrentCheckJersey, setCurrentCheckProduct } from '../../../store/actions';

const AnalysisBottomBar = () => {
    //
    const [loading, setloading] = useState(false)
    const [valUseForFutureReference, setvalUseForFutureReference] = useState(false)
    const checkId = window.location.href.split("id=")[1]
    const dispatch = useDispatch()
    const [checkScore, setcheckScore] = useState(null)

	const [check_status_admin, set_check_status_admin] = useState(null)

    /** @type {ModelCheck} */  
	const check = useSelector((state) => state.checkProduct)

	/** @type {ModelJersey} */
	const checkJersey = useSelector((state) => state.checkJersey)

	const newCheckPhotos = useSelector((state) => state.newCheckPhotos)

    // para buscar o check_status do product review
    useEffect(() => {
        fetchAdminCheckStatus()
    }, [])

    async function fetchAdminCheckStatus() {
        setloading(true)
        let admin_status = await Apis.getBugStatus(checkId)
        set_check_status_admin(admin_status.check_status)
        setloading(false)
    }


    async function onClickSaveAsAuthentic() {
		setloading(true)
        await Apis.sendSaveAsAuthenticOrCounterfeit(checkId, 25)
		await onClickSaveAll()
    }
    
    async function onClickSaveAsInconclusive() {
        setloading(true)
        await Apis.sendSaveAsAuthenticOrCounterfeit(checkId, 15)
        await onClickSaveAll()
    }

    async function onClickSaveAsCounterfeit() {
		setloading(true)
        await Apis.sendSaveAsAuthenticOrCounterfeit(checkId, 5)
		await onClickSaveAll()
    }

	async function setUseForFutureReference(e) {
		setvalUseForFutureReference(!valUseForFutureReference)
        await onClickSaveAll()
	}

    async function onSelectStatus(statusId) {
        setloading(true)
        console.log("statusId: " + statusId)
        console.log("checkId: " + checkId)
        await Apis.sendAdminStatus(checkId, statusId)
        onClickSaveAll()
    }
    
	function onClickWebview() {
        let url = `https://app.kitlegit.com/check/${check.certificate_code}`
        window.open(url, "_blank");
    }

	function onClickPdf() {
        Apis.downloadPDF(check.certificate_code)
    }

	function onClickShare() {
        let strToSAve = "https://admin.kitlegit.com/analysis?id=" + check.id
        navigator.clipboard.writeText(strToSAve)
        alert("Link copied to clipboard")
    }

    /**
	 * Saves the current check product and jersey to the database
	 */
	async function onClickSaveAll() {
		setloading(true)
        try {
            if (checkId == null) {
                let res = await Apis.createNewCheck(check)
                let justGottenCheckId = res.product_id
                await Apis.updateCheckJersey(justGottenCheckId, fixJerseyJson(checkJersey))
                await Apis.sendPhotos(justGottenCheckId, newCheckPhotos)
                await Apis.sendUseForFutureReference(justGottenCheckId, valUseForFutureReference)
                await fetchAdminCheckStatus()
            } else {
                console.log("*------------------------")
                console.log(check)
                console.log("*------------------------")
                await Apis.updateCheck(checkId, check)
                await Apis.updateCheckJersey(checkId, fixJerseyJson(checkJersey))
                await Apis.sendUseForFutureReference(checkId, valUseForFutureReference)
                await fetchAdminCheckStatus()
            }
            // then lets re feetch this same check to make sure everything has been saved
            await fetchCheck()
        } catch (e) {
            console.log("erro");
            console.log(e);
            setloading(false)
        }
		setloading(false)
	}

	/**
	 * Then we check if we are editing a jersey or creating a new check
	 */ 
	async function fetchCheck() {
		setloading(true)  
 
		// lets get and set the check 
		if (checkId == null) { 
			let check = new ModelCheck()
			dispatch(setCurrentCheckProduct(check))
			dispatch(setCurrentCheckJersey(check.jersey))
			// dispatch(setNewCheckPhotos([])) // lets zero out the photos that had been added before
		} else {
			let check = await Apis.getCheck(checkId) 
			dispatch(setCurrentCheckProduct(check)) 
			dispatch(setCurrentCheckJersey(check.jersey)) 
		}

		setcheckScore(check.score ??  null)
		setloading(false) 
	}  





    //
    return (
        <div className='flex-container p-2'>
            {
                loading ? <p>loading</p> :

                <div className='flex-container p-2'>
                    <div className='flex' style={{}}>
                        <span className='flex' style={{width: 60, textAlign: "right"}}>Save as</span>
                        <div className='flex' >
                            <button className='btn  btn-primary mx-1' style={{background: "var(--color-authentic)", color: "black"}} onClick={onClickSaveAsAuthentic} >Authentic</button>
                            <button className='btn  btn-primary mx-1' style={{background: "var(--color-inconclusive)"}}  onClick={onClickSaveAsInconclusive}>Inconclusive</button>
                            <button className='btn  btn-primary mx-1' style={{background: "var(--color-counterfeit)"}}  onClick={onClickSaveAsCounterfeit} >Counterfeit</button>
                        </div>
                        <div className='spacer-hor-32'></div>
                        <div style={{width: 200}}>
                            {
                                check_status_admin != null &&
                                <SelectStatus initialId={check_status_admin} callback={onSelectStatus} isAdmin={true} />
                            }
                        </div>
                        <div className='spacer-hor-32'></div>
                        <div style={{width: 200}}>
                            <CheckBox value={valUseForFutureReference} title={"Use for future reference"} onClick={setUseForFutureReference} />
                        </div>
                    </div>

                    <div className='flex-container-end analysis-bottom-bar-end'>
                        <img className='mx-2'  src={IC_webview} alt=""    onClick={onClickWebview}/>
                        <img className='mx-2'  src={IC_pdf} alt=""        onClick={onClickPdf}/>
                        <img className='mx-2'  src={IC_shareSVG} alt=""   onClick={onClickShare}/>
                        <button className='btn  btn-primary mx-1'         onClick={onClickSaveAll}> Save All </button>
                    </div>
                </div>
            }


        </div>
    )
}

export default AnalysisBottomBar