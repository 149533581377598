import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const SelectSeason = ({initialId, callback}) => {
    const configs = useSelector((state) => state.configs)
    const [selectedValue, setSelectedValue] = useState(initialId);

    function onSelect(event) {
        let value = event.target.value
        setSelectedValue(value)
        callback(value)
    }
    
    return (
        <div>
            <select className="form-select" value={selectedValue ?? configs.ALL_SEASONS[0].id} onChange={onSelect} name='team_id'>
            {
                configs.ALL_SEASONS.map((e) => {
                    return (
                        <option key={e.id} value={e.id}>{e.name}</option>
                    )
                })
            }
            </select>
        </div>
    )
}

export default SelectSeason