import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
import { DEFAULT_OPTIONS } from '../../store/defaultOptions';

export const SelectSwingTag = ({initialId, callback}) => {
    const configs = DEFAULT_OPTIONS.swing_tag
    const [selectedValue, setSelectedValue] = useState((initialId));

    function onSelect(event) {
        let value = event.target.value
        setSelectedValue(value)
        callback(value)
    }
    
    return (
        <div>
            <select className="form-select" value={initialId} onChange={onSelect} name='neck_label'>
            { configs.map((e) => {return (  <option key={e.id} value={e.id}>{e.name}</option>  )}) }
            </select>
        </div>
    )
}

export const SelectSwingTagBarcode = ({initialId, callback}) => {
    const configs = DEFAULT_OPTIONS.barcode_type
    const [selectedValue, setSelectedValue] = useState(initialId);

    function onSelect(event) {
        let value = event.target.value
        setSelectedValue(value)
        callback(value)
    }
    
    return (
        <div>
            <select className="form-select" value={initialId } onChange={onSelect} name='neck_label'>
            { configs.map((e) => {return (  <option key={e.id} value={e.id}>{e.name}</option>  )}) }
            </select>
        </div>
    )
}

export const SelectSwingTagHologram = ({initialId, callback}) => {
    const configs = DEFAULT_OPTIONS.authenticity_hologram_text
    const [selectedValue, setSelectedValue] = useState(initialId);

    function onSelect(event) {
        let value = event.target.value
        setSelectedValue(value)
        callback(value)
    }
    
    return (
        <div>
            <select className="form-select" value={initialId ?? configs[0].id} onChange={onSelect} name='neck_label'>
            { configs.map((e) => {return (  <option key={e.id} value={e.id}>{e.name}</option>  )}) }
            </select>
        </div>
    )
}
