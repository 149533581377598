class ModelCheck {
  /**
   *
   * @param {ModelCheckAnalytics} analytics
   * @param {ModelCheckAnalyticsTraits} analytics_trait
   * @param {*} brand_id
   * @param {*} category_id
   * @param {*} certificate_code
   * @param {*} certificate_file
   * @param {*} check_id
   * @param {*} check_status
   * @param {*} check_status_start
   * @param {*} classification_id
   * @param {*} condition
   * @param {*} cost
   * @param {*} created_at
   * @param {*} currency_id
   * @param {*} data
   * @param {*} family_id
   * @param {*} id
   * @param {*} is_digital
   * @param {*} is_public
   * @param {ModelJersey} jersey
   * @param {*} labelFact
   * @param {*} league_id
   * @param {*} notes
   * @param {*} photos
   * @param {*} product_type
   * @param {*} profile_id
   * @param {*} purchase_place_id
   * @param {*} report_id
   * @param {*} score
   * @param {*} size
   * @param {*} sport
   * @param {*} statement_id
   * @param {*} statement_type
   * @param {*} team_id
   * @param {*} team_type
   * @param {*} triggers
   * @param {*} type
   * @param {*} updated_at
   * @param {*} user_id
   * @param {*} version "player" or "stadium"
   * @param {*} year
   * @param {*} year_manufactured
   * @param {*} year_check
   */
  constructor(
    analytics,
    analytics_trait,
    brand_id = 1,
    category_id,
    certificate_code,
    certificate_file,
    check_id,
    check_status,
    check_status_start,
    classification_id = 1, // 1 outfield; 2 goalkeeper
    condition,
    cost,
    created_at,
    currency_id,
    data,
    family_id,
    id,
    is_digital,
    is_public,
    item_code,
    jersey = new ModelJersey(),
    labelFact,
    league_id,
    notes = "",
    photos = [],
    product_type = 1,
    profile_id,
    purchase_place_id = 1,
    report_id,
    score,
    size = 1,
    sport = 1,
    statement_id,
    statement_type,
    team_id = 1,
    team_type = 1,
    triggers,
    type = 1, // 1 for men; 2 for women
    updated_at,
    user_id,
    version,
    year = 2024,
    year_manufactured,
    year_check = false,
    // added
    team_name,
    brand_name,
    status_name
  ) {
    this.analytics = analytics;
    this.analytics_trait = analytics_trait;
    this.brand_id = brand_id;
    this.category_id = category_id;
    this.certificate_code = certificate_code;
    this.certificate_file = certificate_file;
    this.check_id = check_id;
    this.check_status = check_status;
    this.check_status_start = check_status_start;
    this.classification_id = classification_id;
    this.condition = condition;
    this.cost = cost;
    this.created_at = created_at;
    this.currency_id = currency_id;
    this.data = data;
    this.family_id = family_id;
    this.id = id;
    this.is_digital = is_digital;
    this.is_public = is_public;
    this.item_code = item_code;
    this.jersey = jersey;
    this.labelFact = labelFact;
    this.league_id = league_id;
    this.notes = notes;
    this.photos = photos;
    this.product_type = product_type;
    this.profile_id = profile_id;
    this.purchase_place_id = purchase_place_id;
    this.report_id = report_id;
    this.score = score;
    this.size = size;
    this.sport = sport;
    this.statement_id = statement_id;
    this.statement_type = statement_type;
    this.team_id = team_id;
    this.team_type = team_type;
    this.triggers = triggers;
    this.type = type;
    this.updated_at = updated_at;
    this.user_id = user_id;
    this.version = version;
    this.year = year;
    this.year_manufactured = year_manufactured;
    this.year_check = year_check;

    // added
    this.team_name = team_name;
    this.brand_name = brand_name;
    this.status_name = status_name;
  }

  /**
   *
   * @param {object} json
   * @returns {ModelCheck} this
   */
  fromJson(json) {
   
    this.analytics = new ModelCheckAnalytics().fromJson(json["analytics"]);

    this.analytics_trait = new ModelCheckAnalyticsTraits().fromJson(json["analytics_trait"]);
    this.brand_id = json["brand_id"];
    this.category_id = json["category_id"];
    this.certificate_code = json["certificate_code"];
    this.certificate_file = json["certificate_file"];
    this.check_id = json["check_id"];
    this.check_status = json["check_status"];
    this.check_status_start = json["check_status_start"];
    this.classification_id = json["classification_id"];
    this.condition = json["condition"];
    this.cost = json["cost"];
    this.created_at = json["created_at"];
    this.currency_id = json["currency_id"];
    this.data = json["data"];
    this.family_id = json["family_id"];
    this.id = json["id"];
    this.is_digital = json["is_digital"];
    this.is_public = json["is_public"];
    this.item_code = json["item_code"];
    if (json["jersey"] != null) {
      this.jersey = new ModelJersey().fromJson(json["jersey"])
    }
    this.labelFact = json["labelFact"];
    this.league_id = json["league_id"];
    this.notes = json["notes"];
    this.photos = json["photos"];
    this.product_type = json["product_type"];
    this.profile_id = json["profile_id"];
    this.purchase_place_id = json["purchase_place_id"];
    this.report_id = json["report_id"];
    this.score = json["score"];
    this.size = json["size"];
    this.sport = json["sport"];
    this.statement_id = json["statement_id"];
    this.statement_type = json["statement_type"];
    this.team_id = json["team_id"];
    this.team_type = json["team_type"];
    this.triggers = json["triggers"];
    this.type = json["type"];
    this.updated_at = json["updated_at"];
    this.user_id = json["user_id"];
    this.version = json["version"];
    this.year = json["year"];
    this.year_manufactured = json["year_manufactured"];
    this.year_check = json["year_check"];
    // added
    this.team_name = this.getTeamName();
    this.brand_name = this.getBrandName();
    this.status_name = this.getCheckStatusName();

    return this;
  }

  getCheckStatusName() {
    /**
     * @type {Array}
     */
    let configs = JSON.parse(localStorage.getItem("configs"));
    let item =  configs.check_status[this.check_status];
    if (item == null || item == undefined) {
      return ""
    }
    return item;
  }

  getBrandName() {
    /**
     * @type {Array}
     */
    let configs = JSON.parse(localStorage.getItem("configs"));
    let item = configs.brands.find((e) => e.id === this.brand_id)
    if (item == null || item == undefined) {
      return ""
    }
    return item.name;
  }

  getTeamName() {
    /**
     * @type {Array}
     */
    let configs = JSON.parse(localStorage.getItem("configs"));
    let item =  configs.teams.find((e) => e.id === this.team_id);
    if (item == null || item == undefined) {
      return ""
    }
    return item.name;
  }
}

export class ModelCheckAnalytics {
  /**
   *
   * @param {*} swingTag
   * @param {*} careLabel
   * @param {*} keyIndicators
   * @param {*} purchasePlace
   * @param {*} styleCodeLabel
   * @param {*} authenticityMarking
   */
  constructor(
    swingTag = "",
    careLabel = "",
    keyIndicators = "",
    purchasePlace = "",
    styleCodeLabel = "",
    authenticityMarking = "",
  ) {
    this.swingTag = swingTag;
    this.careLabel = careLabel;
    this.keyIndicators = keyIndicators;
    this.purchasePlace = purchasePlace;
    this.styleCodeLabel = styleCodeLabel;
    this.authenticityMarking = authenticityMarking;
  }
  /**
   *
   * @param {object} json
   * @returns {ModelCheckAnalytics}
   */
  fromJson(jsonStringified) {
    if (jsonStringified == null) {
      this.swingTag =  "";
      this.careLabel = "";
      this.keyIndicators =  "";
      this.purchasePlace =  "";
      this.styleCodeLabel =  "";
      this.authenticityMarking =  "";
    } else {
      let json = JSON.parse(jsonStringified);
      this.swingTag = json["swingTag"] ?? "";
      this.careLabel = json["careLabel"] ?? "";
      this.keyIndicators = json["keyIndicators"] ?? "";
      this.purchasePlace = json["purchasePlace"] ?? "";
      this.styleCodeLabel = json["styleCodeLabel"] ?? "";
      this.authenticityMarking = json["authenticityMarking"] ?? "";
    }
    return this;
  }
}

export class ModelCheckAnalyticsTraits {
  /**
   *
   * @param {*} overall
   * @param {*} swingTag
   * @param {*} careLabel
   * @param {*} neckLabel
   * @param {*} keyIndicators
   * @param {*} purchasePlace
   * @param {*} styleCodeLabel
   * @param {*} additionalDetails
   * @param {*} authenticityMarking
   */
  constructor(
    overall = "",
    swingTag = "",
    careLabel = "",
    neckLabel = "",
    keyIndicators = "",
    purchasePlace = "",
    styleCodeLabel = "",
    additionalDetails = "",
    authenticityMarking = "",
  ) {
    this.overall = overall;
    this.swingTag = swingTag;
    this.careLabel = careLabel;
    this.neckLabel = neckLabel;
    this.keyIndicators = keyIndicators;
    this.purchasePlace = purchasePlace;
    this.styleCodeLabel = styleCodeLabel;
    this.additionalDetails = additionalDetails;
    this.authenticityMarking = authenticityMarking;
  }

  /**
   *
   * @param {object} json
   * @returns {ModelCheckAnalyticsTraits}
   */
  fromJson(jsonStringified) {
    if (jsonStringified == null ) {
      this.overall =  "";
      this.swingTag = "";
      this.careLabel =  "";
      this.neckLabel = "";
      this.keyIndicators = "";
      this.purchasePlace =  "";
      this.styleCodeLabel =  "";
      this.additionalDetails =  "";
      this.authenticityMarking =  "";
    } else {
      let json = JSON.parse(jsonStringified);
      this.overall = json["overall"] ?? "";
      this.swingTag = json["swingTag"] ?? "";
      this.careLabel = json["careLabel"] ?? "";
      this.neckLabel = json["neckLabel"] ?? "";
      this.keyIndicators = json["keyIndicators"] ?? "";
      this.purchasePlace = json["purchasePlace"] ?? "";
      this.styleCodeLabel = json["styleCodeLabel"] ?? "";
      this.additionalDetails = json["additionalDetails"] ?? "";
      this.authenticityMarking = json["authenticityMarking"] ?? "";
    }
    return this;
  }
}


export class ModelJersey {
  /**
   * 
   * @param {number} authenticity_hologram_text 
   * @param {number} authenticity_marking 
   * @param {number} authenticity_marking_type 
   * @param {string | number} authenticity_number 
   * @param {string | number} authenticity_number2 
   * @param {*} authenticity_qr_code 
   * @param {*} authenticity_qr_tag 
   * @param {number} barcode_match_label 
   * @param {number} barcode_type 
   * @param {number} brand_reference 
   * @param {number} care_label 
   * @param {*} care_label_code 
   * @param {number} care_label_country_id 
   * @param {*} care_label_id 
   * @param {number} care_label_markings 
   * @param {*} created_at 
   * @param {number} crest_quality 
   * @param {Array} errorJersey 
   * @param {*} hologram_text 
   * @param {number} id 
   * @param {*} item_code 
   * @param {*} manual_entry 
   * @param {number} mismatches_in_style_code 
   * @param {number} neck_label 
   * @param {*} neck_label_country_id 
   * @param {number} peeling 
   * @param {number} product_id 
   * @param {number} qr_code 
   * @param {*} qr_code_number 
   * @param {number} qr_code_problem 
   * @param {number} spelling_mistakes 
   * @param {number} stitching_quality 
   * @param {Array<string>} style_code 
   * @param {number} style_code_label 
   * @param {number | string} style_code_label_barcode 
   * @param {number} swing_tag 
   * @param {*} swing_tag_style_code 
   * @param {*} swing_tag_terms 
   * @param {number} swing_tag_terms_type 
   * @param {string | number} upc_barcode 
   * @param {string | number} upc_barcode2 
   * @param {number} upc_barcode_problem 
   * @param {*} updated_at 
   */
  constructor(
    authenticity_hologram_text,
    authenticity_marking,
    authenticity_marking_type,
    authenticity_number,
    authenticity_number2,
    authenticity_qr_code,
    authenticity_qr_tag,
    barcode_match_label,
    barcode_type,
    brand_reference,
    care_label,
    care_label_code,
    care_label_country_id,
    care_label_id,
    care_label_markings,
    check_status,
    created_at,
    crest_quality = 3,
    errorJersey,
    hologram_text,
    id,
    item_code,
    manual_entry,
    mismatches_in_style_code,
    neck_label,
    neck_label_country_id,
    peeling = 1,
    product_id,
    qr_code,
    qr_code_number,
    qr_code_problem = 1,
    score, // 0 | 100 | null. se informado, o check é de admin, se não informado é como de um usuário e esse é calculado
    spelling_mistakes,
    stitching_quality = 3,
    style_code,
    style_code_label,
    style_code_label_barcode,
    swing_tag,
    swing_tag_style_code,
    swing_tag_terms,
    swing_tag_terms_type,
    upc_barcode,
    upc_barcode2,
    upc_style_code_label,
    upc_barcode_problem,
    updated_at
  ) {
    this.authenticity_hologram_text = authenticity_hologram_text;
    this.authenticity_marking = authenticity_marking;
    this.authenticity_marking_type = authenticity_marking_type;
    this.authenticity_number = authenticity_number;
    this.authenticity_number2 = authenticity_number2;
    this.authenticity_qr_code = authenticity_qr_code;
    this.authenticity_qr_tag = authenticity_qr_tag;
    this.barcode_match_label = barcode_match_label;
    this.barcode_type = barcode_type;
    this.brand_reference = brand_reference;
    this.care_label = care_label;
    this.care_label_code = care_label_code;
    this.care_label_country_id = care_label_country_id;
    this.care_label_id = care_label_id;
    this.care_label_markings = care_label_markings;
    this.check_status = check_status;
    this.created_at = created_at;
    this.crest_quality = crest_quality;
    this.errorJersey = errorJersey;
    this.hologram_text = hologram_text;
    this.id = id;
    this.item_code = item_code;
    this.manual_entry = manual_entry;
    this.mismatches_in_style_code = mismatches_in_style_code;
    this.neck_label = neck_label;
    this.neck_label_country_id = neck_label_country_id;
    this.peeling = peeling;
    this.product_id = product_id;
    this.qr_code = qr_code;
    this.qr_code_number = qr_code_number;
    this.qr_code_problem = qr_code_problem;
    this.score = score;
    this.spelling_mistakes = spelling_mistakes;
    this.stitching_quality = stitching_quality;
    this.style_code = style_code;
    this.style_code_label = style_code_label;
    this.style_code_label_barcode = style_code_label_barcode;
    this.swing_tag = swing_tag;
    this.swing_tag_style_code = swing_tag_style_code;
    this.swing_tag_terms = swing_tag_terms;
    this.swing_tag_terms_type = swing_tag_terms_type;
    this.upc_barcode = upc_barcode;
    this.upc_barcode2 = upc_barcode2;
    this.upc_style_code_label = upc_style_code_label;
    this.upc_barcode_problem = upc_barcode_problem;
    this.updated_at = updated_at;
  }


  /**
   * 
   * @param {object} json 
   * @returns {ModelJersey}
   */
  fromJson(json) {
    this.authenticity_hologram_text = json["authenticity_hologram_text"];
    this.authenticity_marking = json["authenticity_marking"];
    this.authenticity_marking_type = json["authenticity_marking_type"];

    if (json["authenticity_number"] != null && json["authenticity_number"] !== "") {
      let list = JSON.parse(json["authenticity_number"]);
      this.authenticity_number  = list[0]
      this.authenticity_number2 = list[1]
    } else {
      this.authenticity_number  = ""
      this.authenticity_number2 = ""
    }

    this.authenticity_qr_code = json["authenticity_qr_code"];
    this.authenticity_qr_tag = json["authenticity_qr_tag"];
    this.barcode_match_label = json["barcode_match_label"];
    this.barcode_type = json["barcode_type"];
    this.brand_reference = json["brand_reference"];
    this.care_label = json["care_label"];
    this.care_label_code = json["care_label_code"];
    this.care_label_country_id = json["care_label_country_id"];
    this.care_label_id = json["care_label_id"];
    if (json["care_label_markings"] != null) { 
      this.care_label_markings = json["care_label_markings"] === 1 || json["care_label_markings"] === "1" ? "true" : "false";
    } else {
      this.care_label_markings = null;
    }
    this.created_at = json["created_at"];
    this.crest_quality = json["crest_quality"];
    this.errorJersey = json["errorJersey"];
    this.hologram_text = json["hologram_text"];
    this.id = json["id"];
    this.item_code = json["item_code"];
    this.manual_entry = json["manual_entry"];
    this.mismatches_in_style_code = json["mismatches_in_style_code"];
    this.neck_label = json["neck_label"];
    this.neck_label_country_id = json["neck_label_country_id"];
    this.peeling = json["peeling"];
    this.product_id = json["product_id"];
    this.qr_code = json["qr_code"];

    // the qrcode number comes in an array, but we will always use the first one
    this.qr_code_number = []
    if (json["qr_code_number"]) {
      JSON.parse(json["qr_code_number"]).forEach(qr => this.qr_code_number.push(qr));
    } 

    this.qr_code_problem = json["qr_code_problem"];
    this.spelling_mistakes = json["spelling_mistakes"];
    this.stitching_quality = json["stitching_quality"];

    if (json["style_code"] != null && json["style_code"] !== "") {
      this.style_code = JSON.parse(json["style_code"]);
    } else {
      this.style_code = [""]
    }

    this.style_code_label = json["style_code_label"];
    this.style_code_label_barcode = json["style_code_label_barcode"];
    this.swing_tag = json["swing_tag"];
    this.swing_tag_style_code = json["swing_tag_style_code"];
    this.swing_tag_terms = json["swing_tag_terms"];
    this.swing_tag_terms_type = json["swing_tag_terms_type"];
    
    if (json["upc_barcode"] != null && json["upc_barcode"] !== "") {
      let list = JSON.parse(json["upc_barcode"]);
      this.upc_barcode  = list[0]
      this.upc_barcode2 = list[1]
    } else {
      this.upc_barcode  = ""
      this.upc_barcode2 = ""
    }

    this.upc_style_code_label = json["upc_style_code_label"];
    this.upc_barcode_problem = json["upc_barcode_problem"];
    this.updated_at = json["updated_at"];
    return this
  }


}

/**
 * we need to use this method in order to organize the arrays to send to the server.
 * The attributes: upc_barcode, authenticity_number, style_code, care_label_id, qr_code_number MUST be in an array or string
 * @returns {object}
 */
export const fixJerseyJson = (jersey) => {

    let json = jersey;

    // json.score: number // 0 | 100 | null. se informado, o check é de admin, se não informado é como de um usuário e esse é calculado
    // json.check_status: number // se o admin quiser alterar o check_status dado pelo sistema

    let qr_code_number_list = []
    if (json.qr_code_number != null && json.qr_code_number.length > 0) { qr_code_number_list.push(json.qr_code_number)}  
    
    let style_code_list = []
    if (json.style_code != null && json.style_code.length > 0) { style_code_list.push(json.style_code)}  
    
    let care_label_id_list = []
    if (json.care_label_id != null && json.care_label_id.length > 0) { care_label_id_list.push(json.care_label_id)}   
    
    let authenticity_number_list = []
    if (json.authenticity_number != null && json.authenticity_number.length > 0) { authenticity_number_list.push(json.authenticity_number)}  
    if (json.authenticity_number2 != null && json.authenticity_number2.length > 0) { authenticity_number_list.push(json.authenticity_number2)}  
    
    let up_barcode_list = []
    if (json.upc_barcode != null && json.upc_barcode.length > 0) { up_barcode_list.push(json.upc_barcode) } 
    if (json.upc_barcode2 != null && json.upc_barcode2.length > 0) { up_barcode_list.push(json.upc_barcode2) } 
    
    // we will delete additional keys of the object and recreate them after
    delete json.qr_code_number
    delete json.style_code
    delete json.care_label_id
    delete json.authenticity_number
    delete json.upc_barcode
    delete json.upc_barcode2
    delete json.authenticity_number2
    
    // we will use this [].concat method to avoid that there will be an array inside the array
    
    if (qr_code_number_list.length > 0) 
    json.qr_code_number = [].concat(...qr_code_number_list)
  
    if (style_code_list.length > 0) 
    json.style_code = [].concat(...style_code_list)
    
    if (care_label_id_list.length > 0) 
    json.care_label_id = [].concat(...care_label_id_list)
    
    if (authenticity_number_list.length > 0) 
    json.authenticity_number = [].concat(...authenticity_number_list)
    
    if (up_barcode_list.length > 0) 
    json.upc_barcode = [].concat(...up_barcode_list)

    return json;
}

export default ModelCheck;
