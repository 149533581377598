import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import PageCheckAnalysis from './view/home/PageCheckAnalysis';
import PageTable from './view/table/PageTable';
import PageLogin from './view/login/PageLogin';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import PageAddItem from './view/addItem/PageAddItem';


const router = createBrowserRouter([
  {path: "/", element: <PageLogin/>},
  {path: "/checks", element: <PageTable/>},
  {path: "/analysis", element: <PageCheckAnalysis/>},
  {path: "/add", element: <PageAddItem/>},
])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}> 
		<PersistGate loading={null} persistor={persistor}>
			<RouterProvider  router={router} />
		</PersistGate>
	</Provider>
);

