import actionTypes from "./types";


export const clearEverything = () => ({
    type: actionTypes.CLEAR_EVERYTHING,
    payload: 0,
})

export const setToken = (newValue) => ({
    type: actionTypes.SET_TOKEN,
    payload: newValue,
})

export const setConfigs = (newValue) => ({
    type: actionTypes.SET_CONFIGS,
    payload: newValue,
})


export const setUser = (newValue) => ({
    type: actionTypes.SET_USER,
    payload: newValue
})

/**
 * 
 * @param {string} key 
 * @param {string} value 
 * @returns type and payload
 */
export const setFiltersForTableOfChecks = (val) => ({
    type: actionTypes.SET_FILTER_FOR_CHECKS,
    payload: val
})

export const setAllChecks = (val) => ({
    type: actionTypes.SET_CHECKS,
    payload: val
})

export const setFilteredChecks = (val) => ({
    type: actionTypes.SET_FILTERED_CHECKS,
    payload: val
})

export const clearParams = (val) => ({
    type: actionTypes.CLEAR_PARAMS,
    payload: val
})

export const setSearchBar = (typed) => ({
    type: actionTypes.SET_SEARCH_BAR_FILTER,
    payload: typed
})

export const setSearchBarCheckId = (typed) => ({
    type: actionTypes.SET_SEARCH_BAR_FILTER_CHECK_ID,
    payload: typed
})

export const setSummary = (value) => ({
    type: actionTypes.SET_SUMMARY,
    payload: value
})

export const setEnableDeleteItemFromTable = (value) => ({
    type: actionTypes.SET_ENABLE_DELETE_ITEM_FROM_TABLE,
    payload: value
})

export const setCurrentCheckProduct = (value) => ({
    type: actionTypes.SET_CURRENT_CHECK_PRODUCT,
    payload: value
})
export const setCurrentCheckJersey = (value) => ({
    type: actionTypes.SET_CURRENT_CHECK_JERSEY,
    payload: value
})
export const setNewCheckPhotos = (value) => ({
    type: actionTypes.SET_NEW_CHECK_PHOTOS,
    payload: value
})




// export const someAction = (paylaod) => ({
//     type: SOME_ACTION,
//     payload
// })