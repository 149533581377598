import { DEFAULT_OPTIONS } from "../store/defaultOptions";
import ModelProductReview from "./ModelProductReview";

class ModelCheckList {
    /**
     * 
     * @param {number} adminResultStatusId 
     * @param {string | number} adminResultStatusName 
     * @param {string | number} brandId 
     * @param {string | number} brand
     * @param {string | number} checkStatus 
     * @param {string | number} checkStatusName 
     * @param {string | number} countryId 
     * @param {string | number} date 
     * @param {string | number} email 
     * @param {string | number} id 
     * @param {string | number} notes 
     * @param {string | number} photo 
     * @param {ModelProductReview} productReview 
     * @param {string | number} score 
     * @param {string | number} sport 
     * @param {string | number} teamId 
     * @param {string | number} team
     * @param {string | number} username 
     * @param {string | number} year 
     */
    constructor(
        brandId, brand, checkStatus, checkStatusName, adminResultStatusId,  adminResultStatusName, countryId, date, email, id, notes, photo, productReview,
        score, sport, teamId, team, username, year
    ) {
        this.adminResultStatusId = adminResultStatusId;
        this.adminResultStatusName = adminResultStatusName;
        this.brandId = brandId;
        this.brand = brand;
        this.checkStatus = checkStatus;
        this.checkStatusName = checkStatusName;
        this.countryId = countryId;
        this.date = date;
        this.email = email;
        this.id = id;
        this.notes = notes;
        this.photo = photo;
        this.productReview = productReview;
        this.score = score;
        this.sport = sport;
        this.teamId = teamId;
        this.team = team;
        this.username = username;
        this.year = year;
    }

    /**
     * 
     * @param {object} json 
     * @returns {ModelCheckList} model
     */
    fromJson(json) {
        this.brandId = json["brand_id"];
        this.checkStatus = json["check_status"];
        this.countryId = json["country_id"];
        this.date = json["date"];
        this.email = json["email"];
        this.id = json["id"];
        this.notes = json["notes"];
        this.photo = json["photo"];

        if (json["productReview"] != null) {
            this.productReview = new ModelProductReview().fromJson(json["productReview"])
            this.adminResultStatusId = json["productReview"]["check_status"]
            if (json["productReview"]["ignore"] === 1) {
                this.adminResultStatusId = "ignore"
            }
            this.adminResultStatusName = this.getCheckStatusName(json["productReview"]["check_status"], true)
        } else {
            this.productReview = null
            this.adminResultStatusId = null
            this.adminResultStatusName = null
        }
        
        this.score = json["score"];
        this.sport = json["sport"];
        this.teamId = json["team_id"];
        this.username = json["user_name"];
        this.year = json["year"];

        // mutated data
        this.brand = this.getBrandName();
        this.team = this.getTeamName();
        this.checkStatusName = this.getCheckStatusName(this.checkStatus, false);
        return this;
    }

    getCheckStatusName(chave, isAdminStatus) {
        /**
         * @type {Array}
         */
        let options = DEFAULT_OPTIONS.checkStatus
        if (isAdminStatus) {
            options = DEFAULT_OPTIONS.adminCheckStatus
        }
        let status = options.find(v => v.id === chave)
        if (status == undefined) {
            return null
        }
        return status.name
    }

    getBrandName() {
        /**
         * @type {Array}
         */
        let configs = JSON.parse(localStorage.getItem("configs"));
        let brand = configs.brands.find((e) => e.id === this.brandId)
        if (brand == null || brand == undefined) {
            return ""
        } else {
            return brand.name
        }
    }

    getTeamName() {
        /**
         * @type {Array}
         */
        let configs = JSON.parse(localStorage.getItem("configs"));
        let team =  configs.teams.find((e) => e.id === this.teamId);
        if (team == null || team == undefined) {
            return ""
        } else {
            return team.name
        }
    }
}

export default ModelCheckList