import React, { useEffect, useState } from 'react';
import { SelectBugStatus } from '../../../components/selectors/BugStatus';
// eslint-disable-next-line no-unused-vars
import ModelCheck from '../../../model/ModelCheck';
import Apis from '../../../api/api';
import { useSelector } from 'react-redux';
import { DEFAULT_OPTIONS } from '../../../store/defaultOptions';

const HomeShirtReviewBugs = ({checkData, onSaveAsAuthentic, onSaveAsCounterfeit}) => {
    /**
     * @type {ModelCheck} 
     */
    const check = checkData

    /**@type {ModelCheck} */
    const checkProduct = useSelector(state => state.checkProduct)

    const [loading, setloading] = useState(false)
    const [oldStatusBug, setOldStatusBug] = useState("")

    useEffect(() => { 
        getBugStatus()
    }, [])

    async function getBugStatus() {
        setloading(true)
        let bug = await Apis.getBugStatus(checkProduct.id)
        setOldStatusBug(bug.status_bug)
        setloading(false)
    }

    async function onClickSaveAsAuthentic() {
        onSaveAsAuthentic()
    }

    async function onClickSaveAsCounterfeit() {
        onSaveAsCounterfeit()
    }

    async function handleBugStatus(bugstatus) {
        await Apis.sendBugStatus(checkProduct.id, bugstatus) 
    }

    return (
        <div>
            <h5>Shirt Review & Bugs</h5>
            <div className="grid g3-columns">
                {
                    !loading &&
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Bug status</label>
                        <SelectBugStatus initialId={oldStatusBug} callback={handleBugStatus}  />
                    </div>
                }
            

            </div>
        </div>
    )
}

export default HomeShirtReviewBugs