import React, { useState } from 'react';
// eslint-disable-next-line no-unused-vars
import ModelCheck from '../../../model/ModelCheck';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setNewCheckPhotos } from '../../../store/actions';
import { DEFAULT_OPTIONS } from '../../../store/defaultOptions';


const CheckGalleryNew = () => {
    const configPhotoNames = DEFAULT_OPTIONS.photoNames
    const dispatch = useDispatch()
    const navigate = useNavigate()

    /**  
     * @type {Array} 
     */
    const newCheckPhotos = useSelector(state => state.newCheckPhotos)

	const [photoIndexSelected, setphotoIndexSelected] = useState(null)
	const [showDialog, setShowDialog] = useState(false)
	const [selectedImage, setselectedImage] = useState(0)

	function onClickImage(index, photoIndex) {
		setselectedImage(index)
        setphotoIndexSelected(photoIndex)
        setShowDialog(true)
	}

	function onClickAddImages(index) {
		// setShowDialog(!showDialog)
        navigate("/add")
	}

	function onClickRemoveImage(photoId) { 
		let allphotoshere = newCheckPhotos.filter(p => p.photoId !== photoId)
        dispatch(setNewCheckPhotos(allphotoshere));
	}


    return (
        <div>

			{/* {
				newCheckPhotos[selectedImage] !== null &&  
                <div  className="img-square-big">
                    <img className="img-square-big" src={newCheckPhotos[selectedImage].url} alt=""/>
                </div>
			} */} 

			 
			<div className="img-grid-new-photos">
				{
					newCheckPhotos.map((p, index) => {
						return (
                            <div className='container-just-added-photo'>
                                <img className="XX" onClick={() => { onClickImage(index, newCheckPhotos[index].photoId) }} key={index} src={newCheckPhotos[index].url} alt=""/>
                                <div>
                                    {
                                        newCheckPhotos[index].names.map(nam => {
                                            return <p className='mx-3'>{
                                                configPhotoNames.map(ppp => {
                                                    if (ppp.id === nam) {
                                                        return <>{ppp.title}</>
                                                    }
                                                })
                                            }</p>
                                        })
                                    }

                                    <button className='btn btn-secondary mx-3' onClick={() => {onClickRemoveImage(newCheckPhotos[index].photoId)}}>Remove this image</button>
                                </div>
                            </div>
                        )
					})
				}
			</div>

			{
				// showDialog && <EachAddedPhoto onCliclClose={onClickAddImages} seletecPhotoId={photoIndexSelected}/>
			}

            <h2>{photoIndexSelected}</h2>


                <div  className="img-square-big">
					<button className='btn btn-primary' onClick={onClickAddImages}>+ Add images</button>
				</div>
        </div>
    )
}

export default CheckGalleryNew