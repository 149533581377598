

class ModelPhoto {
    /**
     * 
     * @param {number} photoId 
     * @param {string} url - the photo data itself
     * @param {string} photoData - the photo data itself
     * @param {number[]} names - arrays with indexes for the names (shirtFront, swingTag, etc) like = [1, 5, 12, ...]
     */
    constructor(photoId, url, photoData, names) {
        this.photoId = photoId;
        this.url = url;
        this.photoData = photoData;
        this.names = names;
    }
}

export default ModelPhoto
//{ photoId: list.length,   url: imageUrl, names: selectedCheckbox} 